import { useNavigate } from 'react-router-dom'

import background from '../assets/images/home.png'
import theme from '../styles/theme'

import { BasicBtn } from '../components/Buttons'

function Home() {
  const navigate = useNavigate()

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
    }}>
      <div style={{
        width: '50%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        opacity: '0.65',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div>
          <b style={{ fontSize: '7em', color: theme.colors.primary }}>AKIVE</b>
          <p style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
            <span style={{ fontSize: '1.5em' }}>모든 학습 관리 툴을 하나로 모아!</span><br/><br/>
            학습 계획 수립하고, 계획 체크하고, 집중력 관리하고, 다이어리 쓰고 ... <b></b><br/><br/>
            이 모든 것을 위해 여러 툴을 사용하신다고요?<br/><br/>
            이젠 <b style={{ color: theme.colors.primary }}>'AKIVE'</b> 하나로 관리하세요!
          </p>
          <br/>
          <BasicBtn
            height='40px'
            width='170px'
            onClick={() => navigate('/sign-in')}
            style={{ fontWeight: 'bold' }}
          >로그인 하러 가기
          </BasicBtn>
          <BasicBtn
            height='40px'
            width='170px'
            onClick={() => navigate('/email-auth?purpose=join')}
            style={{marginLeft: '10px', fontWeight: 'bold'}}
          >회원 가입 하러 가기
          </BasicBtn>
        </div>
      </div>
    </div>
  )
}

export default Home
