import styled from 'styled-components'

export const FlexContainer = styled.div`
  display: flex;
  height: 40px;
  margin-top: ${props => props.marginTop || '35px'};
`

export const BtnContainer = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
`

export const ModalBox = styled.div`
  background-color: white;
  border-radius: 10px;
  height: ${props => props.height};
  width: ${props => props.width};
  left: 50%;
  max-height: ${props => props.max_height};
  min-height: ${props => props.min_height};
  overflow: auto;
  padding: 1.2em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 1.2em;
  }
`

export const ModalContents = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  height: 100vh;
  width: 100vw;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999;
`

export const Title = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0;
`

export const Sign = styled.p`
  font-weight: bold;
  margin: 10px;
`