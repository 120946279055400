import styled from 'styled-components'

export const Planner = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  & td, th { border: 1px solid ${props => props.theme.colors.lightGray}; text-align: center; }
  .update-subject { cursor: pointer }

  .add-icon {
    cursor: pointer;
    height: 1.8em;
    width: 1.8em;
    fill: white;
    vertical-align: middle;
    margin-left: 10px;
  }
`

export const MainHeader = styled.th`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: ${props => props.fontSize};
  height: ${props => props.height || '50px' };
  width: ${props => props.width || '150px' };
`

export const SubHeader = styled.th`
  background-color: ${props => props.theme.colors.skyblue};
  font-size: 0.8em;
  height: 25px;
  width: 150px;
`

export const Data = styled.td`
  border: 1px solid ${props => props.theme.colors.lightGray};
  font-size: 0.8em;
  height: 50px;
  width: 150px;
  text-align: center;
`

export const TotalPlanData = styled(Data)`
  font-weight: bold;
  background-color: #FFFFF6;
`