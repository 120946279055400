import styled from 'styled-components'

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
export const AuthBox = styled.div`
  width: ${props => props.width};
  height:  ${props => props.height};
  background-color: white;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`

export const Title = styled.h2`
  text-align: center;
  margin-top: 0;
  font-size: 30px;
  color: ${props => props.theme.colors.primary};
  font-weight: bolder;
`

export const FindList = styled.ul`
  text-align: center;
  color: ${props => props.theme.colors.gray};
  font-size: 12px;
  margin-top: 30px;
  padding: 0;
  
  & li:nth-child(2)::before {
    content: '|';
    color: ${props => props.theme.colors.gray};
    padding: 10px;
  }
  
  & a:visited, a:hover, a:active, a:link { color: ${props => props.theme.colors.gray} }
`

export const FindItem = styled.li`
  display: inline;
`