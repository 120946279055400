import axios from 'axios'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import api from '../../services/api'
import { ProfileContext } from '../../contexts'
import { getCookie } from '../../utils/manageCookie'
import useModal from '../../hooks/useModal'

import { BasicBtn, CompleteBtn } from '../../components/Buttons'
import { BasicTextFieldForForm } from '../../components/InputBoxes'
import ListItemText from '@mui/material/ListItemText'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import defaultProfileImg from '../../assets/images/profile_icon.svg'

export default function ModifyProfile() {
  const { control, handleSubmit, setValue } = useForm()
  const [newProfileImg, setNewProfileImg] = useState(null)
  const [profileImg, setProfileImg] = useState(null)
  const { docs, setDocs } = useContext(ProfileContext)
  const { openModal } = useModal()
  const [anchorEl, setAnchorEl] = useState()
  const selectFile = useRef('')
  const { nickname } = getCookie('akiveUser')

  useEffect(() => {
    setProfileImg(docs?.img_url)
  }, [docs])

  async function CompleteModify(data) {
    try {
      if (newProfileImg) {
        const fileType = newProfileImg.name.split('.').pop()
        const fileName = `${moment().unix()}.${fileType}`
        const signedUrl = await api.get(`/contents/signed-urls/profile/${fileName}`).then(result => result.data.signed_url)
        if (signedUrl) {
          await axios.put(signedUrl, newProfileImg, { headers: { 'Content-Type': 'image/*'} })
          data.new_img_url = `${process.env.REACT_APP_CLOUDFRONT_URL}/profile-img/${fileName}`
        }
        setNewProfileImg(null)
      } else {
        data.new_img_url = profileImg
      }

      api.patch(`/users/${nickname}`, { ...data }).then(result => {
        setDocs({ email: docs.email, ...result.data })
        openModal({ type: 'alert', props: { isError: false, contents: '수정되었습니다.' }})
      })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  async function handleFileChange(e) {
    const file = e.target.files[0]
    const fileType = file.name.split('.').pop()
    if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png') {
      return openModal({ type: 'alert', props: { isError: true, contents: 'jpg, jpeg, png 파일만 업로드 가능합니다' }})
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setProfileImg(reader.result)
    }

    setNewProfileImg(file)
  }

  async function checkNickname(v) {
    return api.get(`/users/check-nickname`, { params: { new_nickname: v }})
      .then(result => result.status === 200)
      .catch((err) => ! err.response.status === 409)
  }

  function clickModifyImg(e) { setAnchorEl(e.currentTarget) }
  function closeMenu() { setAnchorEl(null) }

  return (
    <>
      <Container>
        <Box width='45%' height='60%'>
          {/*프로필 이미지*/}
          <div style={{ display: 'block', marginBottom: '10px' }}>
            <img
              id='profile-icon'
              src={profileImg || defaultProfileImg}
              alt='profile img'
              style={{ borderRadius: '50%', height: '150px', width: '150px' }}
            />
            <button style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '50%',
              borderWidth: '1px',
              height: '40px',
              width: '40px',
              marginLeft: '-7%',
            }}>
              <CameraAltIcon onClick={clickModifyImg}/>
              <input
                accept='image/jpg, image/png, image/jpeg'
                onChange={handleFileChange}
                ref={selectFile}
                type='file'
                style={{ display: 'none' }}
              />
            </button>
            <Menu
              id='fade-menu'
              anchorEl={anchorEl}
              MenuListProps={{ 'aria-labelledby': 'fade-button' }}
              onClose={closeMenu}
              open={Boolean(anchorEl)}
            >
              <MenuItem
                onClick={() => {
                  selectFile.current.click()
                  closeMenu()
                }}
              >
                <ListItemText>사진 변경</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfileImg(null)
                  setNewProfileImg(null)
                  closeMenu()
                }}
              >
                <ListItemText>사진 삭제</ListItemText>
              </MenuItem>
            </Menu>
          </div>
          {/*이메일 및 비번 변경*/}
          <div style={{ alignItems: 'center', display: 'flex', margin: '0 auto', marginTop: '20px', width: '60%' }}>
            <strong style={{ flex: 7 }}>{docs?.email}</strong>
            <BasicBtn onClick={() => openModal({ type: 'modifyPw', props: { nickname } })} height='40px'  width='120px' style={{ marginLeft: '15px', flex: 3 }}>비밀번호 변경</BasicBtn>
          </div>
          {/*닉네임과 목표 및 다짐*/}
          <div style={{ width: '60%', margin: '0 auto' }}>
            <BasicTextFieldForForm
              control={control}
              defaultValue={docs?.nickname}
              name='new_nickname'
              label='닉네임'
              minWidth='100%'
              _onChange={(e) => setValue('new_nickname', e.target.value, { shouldValidate: true })}
              rules={{
                required: '닉네임을 입력해 주세요',
                maxLength: { value: 10, message: '10자를 초과할 수 없습니다' },
                validate: async (v) => await checkNickname(v) || '중복된 닉네임입니다', // TODO debounce 적용
                pattern: {
                  value: /^(?!admin|user).*/,
                  message: '사용할 수 없는 닉네임입니다'
                }
              }}
              style={{ marginTop: '30px', display: 'flex' }}
            />
            <BasicTextFieldForForm
              control={control}
              defaultValue={docs?.aim}
              name='new_aim'
              label='목표 및 다짐'
              minWidth='100%'
              rules={{ maxLength: { value: 30, message: '30자를 초과할 수 없습니다' } }}
              style={{ marginTop: '30px' }}
            />
          </div>
          <CompleteBtn
            onClick={handleSubmit(CompleteModify)}
            height='40px'
            width='120px'
            style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)' }}
          >수정하기</CompleteBtn>
        </Box>
      </Container>
      <BasicBtn
        onClick={() => openModal({ type: 'leave', props: { nickname } })}
        height='40px'
        width='120px'
        style={{ position: 'absolute', bottom: '30px', right: '50px' }}
      >회원 탈퇴</BasicBtn>
    </>
  )
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`

export const Box = styled.div`
  position: relative;
  width: ${props => props.width};
  height:  ${props => props.height};
  background-color: white;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`