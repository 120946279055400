import _ from 'lodash'
import { useForm } from 'react-hook-form'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'
import { BasicTextFieldForForm, BasicNumberInputForForm } from '../../InputBoxes'
import { CompleteBtn } from '../../Buttons'

export default function UpdateSubjectModal(props) {
  const { control, handleSubmit } = useForm()
  const { closeModal } = useModal()

  async function addSubject(data) {
    try {
      api.post(`sessional-plans/${props.sessionalPlanIdx}/total-quantities`, data).then(result => {
        const _sessionalPlan = _.cloneDeep(props.sessionalPlan)
        _sessionalPlan.total_quantity.push(result.data.total_quantity)
        props.setSessionalPlan(_sessionalPlan)
        closeModal()
      })
    } catch (err) {
      console.error(err)
    }
  }

  async function modifySubject(data) {
    try {
      api.put(`sessional-plans/${props.sessionalPlanIdx}/total-quantities/${props?.totalQuantity?.idx}`, data).then(result => {
        let _sessionalPlan = _.cloneDeep(props.sessionalPlan)
        const modifiedIdx = _.findIndex(_sessionalPlan.total_quantity, tq => tq.idx === props?.totalQuantity?.idx)
        _sessionalPlan.total_quantity[modifiedIdx] = result.data.total_quantity
        props.setSessionalPlan(_sessionalPlan)
        closeModal()
      })
    } catch (err){
      console.error(err)
    }
  }

  return (
    <>
      <S.Background />
      <S.ModalBox height='400px' width='350px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>{props?.totalQuantity?.idx ? '세션 영역 수정' : '세션 영역 추가'}</S.Title>
          <S.FlexContainer marginTop='30px' marginBottom='20px'>
            <BasicTextFieldForForm
              control={control}
              defaultValue={props?.totalQuantity?.subject_category || null}
              label='분류'
              name='subject_category'
              rules={{ required: '분류를 입력해 주세요' }}
              style={{ marginRight: '10px' }}
            />
            <BasicTextFieldForForm
              control={control}
              defaultValue={props?.totalQuantity?.subject || null}
              label='영역'
              name='subject'
              rules={{ required: '영역을 입력해 주세요' }}
            />
          </S.FlexContainer>
          <S.FlexContainer marginTop='35px' marginBottom='20px'>
            <BasicNumberInputForForm
              control={control}
              defaultValue={props?.totalQuantity?.total_quantity || 0}
              label='session 분량'
              name='total_quantity'
              maxWidth='50%'
              rules={{ required: 'session 분량을 입력해 주세요' }}
              style={{ marginRight: '10px' }}
            />
            <BasicTextFieldForForm
              control={control}
              defaultValue={props?.totalQuantity?.unit || null}
              label='단위 (e.g. page)'
              name='unit'
              rules={{ required: '단위를 입력해 주세요' }}
            />
          </S.FlexContainer>
          <BasicTextFieldForForm
            control={control}
            defaultValue={props?.totalQuantity?.memo || null}
            label='메모'
            name='memo'
            minWidth='100%'
            style={{ marginTop: '40px' }}
          />
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(props?.totalQuantity?.idx ? modifySubject : addSubject)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}