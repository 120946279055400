import moment from 'moment'
import { useContext, useEffect, useState } from 'react'

import api from '../../services/api'
import { StaticsContext } from '../../contexts'

import * as S from './Main.styles'
import { UpdateAttendanceBtn } from '../Buttons'
import { DailyLogTable } from '../Tables'

import { ReactComponent as DailyLogIcon } from '../../assets/images/menu/daily_log_icon.svg'

export default function TodayDailyLog() {
  const date = moment().format('YYYY-MM-DD')
  const { setStatics } = useContext(StaticsContext)
  const [attendanceInfo, setAttendanceInfo] = useState({})
  const [dailyLog, setDailyLog] = useState([])

  useEffect(() => {
    api.get(`/attendances/${date}`).then(result => setAttendanceInfo(result.data))
    api.get(`/daily-logs/${date}`).then(result => setDailyLog(result.data.daily_log))
  }, [])

  return (
    <>
      <S.FlexContentsBox margin='10px 10px 0 0'>
        <DailyLogIcon className='title-icon' width='30px' fill='black'/>
        <S.Title fontSize='1.3em' float='left'>오늘의 데일리 로그</S.Title>
        {/*출석 인증*/}
        <UpdateAttendanceBtn
          attendanceInfo={attendanceInfo}
          setAttendanceInfo={setAttendanceInfo}
          date={date}
          height='150px'
          width=' 200px'
        />
        {/*오늘의 데일리(일일) 로그*/}
        <DailyLogTable
          dailyLog={dailyLog}
          date={date}
          setDailyLog={setDailyLog}
          setStatics={setStatics}
        />
      </S.FlexContentsBox>
    </>
  )
}