import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'
import { useRef } from 'react'

import api from '../services/api'
import useModal from '../hooks/useModal'

import theme from '../styles/theme'

import { ReactComponent as ImgIcon } from '../assets/images/image_icon.svg'

/**
 * 기본 버튼
 */
export const BasicBtn = styled.button`
  color: white;
  cursor: pointer;
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 10px;
  font-size: ${props => props.fontSize};
  height: ${props => props.height};
  width: ${props => props.width};
  line-height:  ${props => props.lineHeight};
  margin: ${props => props.margin};
  text-align: center;
`

/**
 * 취소 버튼
 */
export const CancelBtn = styled(BasicBtn)`
  color: ${props => props.theme.colors.primary};
  background-color: #FFFFFF;
  border-color: ${props => props.theme.colors.primary};
  border-style: solid;
  font-weight: 700;
`

/**
 * 확인 및 완료 버튼
 */
export const CompleteBtn = styled(BasicBtn)`
  color: ${props => props.disabled ? props.theme.colors.gray : '#FFFFFF'};
  cursor: ${props => props.disabled ? 'default' : 'pointer' };
  background-color: ${props => props.disabled ? props.theme.colors.lightGray : null};
  font-weight: 700;
`

/**
 * 출석 인증 버튼
 */
const AttendanceBtn = styled.button`
  color: ${props => props.theme.colors.lightGray};
  cursor: pointer;
  background: url(${props => props.img});
  background-size: cover;
  border-color: ${props => props.theme.colors.lightGray};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: ${props => props.height};
  width: ${props => props.width};
  margin: 10px auto;
`

/**
 * 출석 인증 추가/삭제 버튼
 */
export function UpdateAttendanceBtn({ date, height, width, attendanceInfo, setAttendanceInfo }) {
  const { openModal } = useModal()
  const selectFile = useRef('')

  function cancelAttendance(date) {
    try {
      api.delete(`attendances/${date}`).then(() => setAttendanceInfo(null))
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  async function handleFileChange(e) {
    const file = e.target.files[0]
    const fileType = file.name.split('.').pop()

    if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png') {
      return openModal({ type: 'alert', props: { isError: true, contents: 'jpg, jpeg, png 파일만 업로드 가능합니다' }})
    }

    const fileName = `${moment().unix()}.${fileType}`

    try {
      const signedUrl = await api.get(`/contents/signed-urls/attendance/${fileName}`).then(result => result.data.signed_url)
      if (! signedUrl) return
      await axios.put(signedUrl, file, { headers: { 'Content-Type': 'image/*'} })
      const attendanceResult = await api.post(`/attendances/${date}`
        , { img_url: `${process.env.REACT_APP_CLOUDFRONT_URL}/attendance-prove/${fileName}` })
        .then(result => result.data)
      setAttendanceInfo(attendanceResult)
      openModal({ type: 'alert', props: { isError: false, contents: attendanceResult.message }})
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  return (
    <>
      {
        _.isEmpty(attendanceInfo)
          ?
          <AttendanceBtn
            height={height}
            width={width}
            onClick={() => selectFile.current.click()}>
            <p>출석 인증을 해주세요</p>
            <ImgIcon width='40%' fill={theme.colors.lightGray}/>
            <input
              accept='image/jpg, image/png, image/jpeg'
              onChange={handleFileChange}
              ref={selectFile}
              type='file'
              style={{ display: 'none' }}
            />
          </AttendanceBtn>
          :
          <AttendanceBtn
            height={height}
            width={width}
            img={attendanceInfo.img_url}
            onClick={() => {
              openModal({
                type: 'confirm',
                props: {
                  contents: '정말로 출석 인증을 취소하시겠습니까?',
                  callbackFun: cancelAttendance,
                  itemIdx: date,
                }
              })
            }}
          />
      }
    </>
  )
}