import _ from 'lodash'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

import api from '../services/api'
import useModal from '../hooks/useModal'

import BasicPagination from '../components/Paginations'
import { ReactComponent as DailyLogIcon } from '../assets/images/menu/daily_log_icon.svg'

export default function DailyLog() {
  const limit = 7
  const { openModal } = useModal()
  const [dailyLog, setDailyLog] = useState()

  useEffect(() => {
    try {
      api.get('/daily-logs', { params: { page: 1, limit }}).then(result => setDailyLog(result.data))
    } catch (err) {
      console.error(err)
    }
  }, [])

  function handlePageChange(e, v) {
    try {
      api.get('/daily-logs', { params: { page: v, limit }}).then(result => setDailyLog(result.data))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div style={{ margin: '30px' }}>
      <DailyLogIcon
        className='title-icon'
        width='1.8em' fill='black'
        style={{ float: 'left', marginTop: '12px', marginRight: '12px' }}
      />
      <h1>데일리 로그</h1>
      <p style={{ margin: '20px 0 40px 0' }}>매 분, 매시간 내가 무엇을 하는지, 얼마나 집중하는지 기록해 보세요</p>
      <div
        style={{
          margin: 'auto',
          minHeight: '30%',
          width: '50%',
          position: 'relative',
        }}
      >
        <p>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {parseInt(dailyLog?.pagination?.total_num).toLocaleString('ko-KR')}
          </span>
          개의 데일리 로그가 있어요
        </p>
        <>
          {
            _.map(dailyLog?.daily_log_list, dl => {
              return (
                <BoardItem
                  onClick={() =>
                    openModal({
                      type: 'getDailyLog',
                      props: { date: dl.date }
                    })
                  }
                >
                  <HR/>
                  <DailyLogIcon
                    className='title-icon'
                    width='1.5em'
                    fill='gray'
                    style={{ float: 'left' }}
                  />
                  <p style={{ fontSize: '1.2em' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        [{dl.date}]
                      </span>
                    &emsp;데일리 로그
                  </p>
                </BoardItem>
              )
            })
          }
        </>
      </div>
      <BasicPagination totalPage={dailyLog?.pagination.total_page} onChange={handlePageChange}/>
    </div>
  )
}

const BoardItem = styled.div`
  cursor: pointer;
  height:60px;
  line-height:60px;
  text-align: center;

  p, h1 { margin: 0 };
  
  &:hover{  
    background-color : ${(props => props.theme.colors.skyblue)};
  }

  .title-icon {
    height:60px;
    line-height:60px;
  }
`

const HR = styled.h1`
  background: ${props => props.theme.colors.lightGray};
  border: 0;
  height: 0.5px;
`
