import { useController } from 'react-hook-form'
import styled from 'styled-components'

import TextField from '@mui/material/TextField'

export const SingleLineInput = styled.input`
  position: relative;
  overflow: hidden;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: 0 0 10px;
  padding: 5px 39px 5px 11px;
  border: solid 1px ${props => props.theme.colors.lightGray};
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  display: block;
`

/**
 * 폼 전용 텍스트 필드 - 기본형
 */
export function BasicTextFieldForForm({ control, defaultValue, isDisabled, name, label, minWidth, maxWidth, rules, style, _onChange }) {
  const { field, fieldState } = useController({ control, name, rules, defaultValue })

  return (
    <TextField
      defaultValue={field.value}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      inputRef={field.ref}
      label={label}
      size='small'
      style={style}
      sx={{ minWidth, maxWidth }}
      onChange={_onChange || field.onChange}
      onBlur={field.onBlur}
      disabled={isDisabled}
      variant='outlined'
      value={field.value}
    />
  )
}

/**
 * 폼 전용 넘버 필드 - 기본형
 */
export function BasicNumberInputForForm({ control, defaultValue, name, label, minWidth, maxWidth, rules, style }) {
  const { field, fieldState } = useController({ control, name, rules, defaultValue })

  return (
    <TextField
      defaultValue={field.value}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      inputRef={field.ref}
      label={label}
      type='number'
      sx={{ minWidth, maxWidth }}
      onChange={field.onChange}
      size='small'
      style={style}
    />
  )
}

/**
 * 폼 전용 패스워드 필드 - 기본형
 */
export function BasicPwInputForForm({ control, name, label, minWidth, maxWidth, rules, style }) {
  const { field, fieldState } = useController({ control, name, rules })

  return (
    <TextField
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      inputRef={field.ref}
      label={label}
      type='password'
      sx={{ minWidth, maxWidth }}
      onChange={field.onChange}
      size='small'
      style={style}
    />
  )
}

/**
 * 3자리 이하  숫자 input field
 */
export function SmallNumberInput({ number, onBlur }) {
  return (
    <input
      defaultValue={number || 0}
      style={{ height: '20px', width: '80%', textAlign: 'center' }}
      type='number'
      autoFocus
      onBlur={onBlur}
    />
  )
}
