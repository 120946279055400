import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import api from '../../services/api'
import { ExternalClickContext, ProfileContext } from '../../contexts'
import { getCookie } from '../../utils/manageCookie'

import Sidebar from '../../components/Sidebar'

import defaultProfileImg from '../../assets/images/profile_icon.svg'

function Main() {
  const [ docs, setDocs ] = useState([])
  const user = getCookie('akiveUser')
  const [externalClickEvent, setExternalClickEvent] = useState(null)

  useEffect(() => {
    async function getUserInfo() {
      return api.get(`/users/${user.nickname}`).then(result => result.data)
    }

    getUserInfo().then((data) => {
      setDocs(data)
    })
  }, [])

  /**
   * 외부 영역 클릭 시 '오늘 할 일' 내의 input 태그가 닫히도록 한다.
   */
  function endInput(e) {
    if (! e.target.matches('input')) {
      return setExternalClickEvent(e)
    }
    setExternalClickEvent(null)
  }

  return (
    <MainContainer>
      <Sidebar imgUrl={docs.img_url || defaultProfileImg} aim={docs.aim || '목표와 다짐을 등록 해보세요!'} nickName={docs.nickname}/> {/*flex: 1*/}
      <OtherPageWrapper onClick={(e) => endInput(e)}> {/*flex: 5*/}
        <ExternalClickContext.Provider value={externalClickEvent}>
          <ProfileContext.Provider value={{ docs, setDocs }}>
            <Outlet/>
          </ProfileContext.Provider>
        </ExternalClickContext.Provider>
      </OtherPageWrapper>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
`

const OtherPageWrapper = styled.div`
  flex: 5;
  overflow-y:scroll;
`

export default Main
