import { ResponsiveLine } from '@nivo/line'

export default function BasicLineChart({ data, min = '0', max, xAxis,  yAxis, margin, colors = { scheme: 'set1' }, isAxisBottom = true, pointSize = 5 }) {
  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min,
        max,
        stacked: true,
        reverse: false
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={
        isAxisBottom
          ?
          {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xAxis,
            legendOffset: 36,
            legendPosition: 'middle',
            truncateTickAt: 0
          }
          : null
      }
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxis,
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0
      }}
      enableGridX={false}
      curve='monotoneX'
      colors={colors}
      pointSize={pointSize}
      pointColor={{ from: 'color', modifiers: [] }}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}