import React from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

import { modalSelector } from '../../store/modalSlice'

import AlertModal from './AlertModal'
import ConfirmModal from './ConfirmModal'
import UpdateDailyLogModal from './daily_logs/UpdateDailyLogModal'
import GetDailyLogModal from './daily_logs/GetDailyLogModal'
import UpdateSessionalPlanModal from './plans/UpdateSessionalPlanModal'
import UpdateSubjectModal from './plans/UpdateSubjectModal'
import UpdateWeeklyDelayModal from './plans/UpdateWeeklyDelayModal'
import UpdateDailyDelayModal from './plans/UpdateDailyDelay'
import UpdateQuantityModal from './plans/UpdateQuantityModal'
import SelectReportTypeModal from './SelectReportTypeModal'
import LeaveModal from './profile/LeaveModal'
import ModifyPwModal from './profile/ModifyPwModal'

const MODAL_COMPONENTS = {
  alert: AlertModal,
  confirm: ConfirmModal,
  updateDailyLog: UpdateDailyLogModal,
  getDailyLog: GetDailyLogModal,
  updateSessionalPlan: UpdateSessionalPlanModal,
  updateSubject: UpdateSubjectModal,
  updateWeeklyDelay: UpdateWeeklyDelayModal,
  updateDailyDelay: UpdateDailyDelayModal,
  updateQuantity: UpdateQuantityModal,
  selectReportType: SelectReportTypeModal,
  leave: LeaveModal,
  modifyPw: ModifyPwModal,
}

function ModalContainer() {
  const modalList = useSelector(modalSelector)

  const renderModal = modalList.map(({ type, props }) => {
    const ModalComponent = MODAL_COMPONENTS[type]
    return <ModalComponent key={type} { ...props } />
  })
  return createPortal(
    <>{renderModal}</>
    , document.getElementById("modal")
  )
}

export default ModalContainer