import _ from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import { BasicBtn } from '../../components/Buttons'
import BasicPagination from '../../components/Paginations'
import ReportingStatus from '../../components/ReportingStatus'

import { ReactComponent as ReportIcon } from '../../assets/images/menu/report_icon.svg'

export default function ReportList() {
  const [report, setReport] = useState()
  const [reportingStatus, setReportStatus] = useState()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const limit = 7
  const date = moment().format('YYYY-MM-DD')

  useEffect(() => {
    try {
      api.get(`/reports/lists`, { params: { page: 1, limit } }).then(result => setReport(result.data))
      api.get(`/reports/infos/reporting-status/${date}`).then(result => setReportStatus(result.data.reporting_status.this_year))
    } catch (err) {
      console.error(err)
    }
  }, [])

  function handlePageChange(e, v) {
    try {
      api.get('/reports/lists', { params: { page: v, limit }}).then(result => setReport(result.data))
    } catch (err) {
      console.error(err)
    }
  }


  return(
    <div style={{ margin: '30px', position: 'relative' }}>
      <ReportIcon
        className='title-icon'
        width='2em'
        fill='black'
        style={{ float: 'left', marginTop: '12px', marginRight: '12px' }}
      />
      <h1>리포트</h1>
      <BasicBtn
        height='40px'
        width='120px'
        style={{ position: 'absolute', right: '0px', top: '0px'  }}
        onClick={() => {
          try {
            api.get(`/reports/infos/types/${date}`)
              .then(result => {
                const typeList = result.data.report_type_list
                if (typeList.length === 1) {
                  navigate(`../write?type=${typeList[0]}&date=${date}`)
                } else {
                  openModal({ type: 'selectReportType', props: { typeList, date }})
                }
              })
          } catch (err) {
            console.error(err)
            openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
          }
        }}
      >추가</BasicBtn>
      <div
        style={{
          margin: 'auto',
          minHeight: '30%',
          width: '50%',
          position: 'relative',
        }}
      >
        <ReportingStatus name='연간 리포트' reportStatusList={[reportingStatus?.annually_reporting_status]}/>
        <ReportingStatus name='반기 리포트' reportStatusList={reportingStatus?.biannually_reporting_status_list}/>
        <ReportingStatus name='월간 리포트' reportStatusList={reportingStatus?.this_biannually?.monthly_reporting_status_list}/>
        <ReportingStatus name='주간 리포트' reportStatusList={reportingStatus?.this_biannually?.this_monthly?.weekly_reporting_status_list}/>
        <p>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {parseInt(report?.pagination?.total_num).toLocaleString('ko-KR')}
          </span>
          개의 리포트가 있어요
        </p>
        <>
          {
            _.map(report?.report_list, rl => {
              return (
                <BoardItem
                  onClick={() => navigate(`../${rl.idx}?type=${rl.report_type}&date=${rl.date}`) }
                >
                  <HR/>
                  <ReportIcon
                    className='title-icon'
                    width='1.5em'
                    fill='gray'
                    style={{ float: 'left' }}
                  />
                  <p style={{ fontSize: '1.2em' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        [{rl.date}]
                      </span>
                      &emsp;{rl.report_type} 리포트
                  </p>
                </BoardItem>
              )
            })
          }
        </>
      </div>
      <BasicPagination totalPage={report?.pagination.total_page} onChange={handlePageChange}/>
    </div>
  )
}

const BoardItem = styled.div`
  cursor: pointer;
  height:60px;
  line-height:60px;
  text-align: center;

  p, h1 { margin: 0 };
  
  &:hover{  
    background-color : ${(props => props.theme.colors.skyblue)};
  }

  .title-icon {
    height:60px;
    line-height:60px;
  }
`

const HR = styled.h1`
  background: ${props => props.theme.colors.lightGray};
  border: 0;
  height: 0.5px;
`