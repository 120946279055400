import { Link } from 'react-router-dom'
import styled from 'styled-components'

import DailyLogIcon from '../assets/images/menu/daily_log_icon.svg'
import HomeIcon from '../assets/images/menu/home_icon.svg'
import ModifyIcon from '../assets/images/crud/edit_icon.svg'
import PlannerIcon from '../assets/images/menu/planner_icon.svg'
import ReportIcon from '../assets/images/menu/report_icon.svg'
import SettingIcon from '../assets/images/menu/setting_icon.svg'

function Sidebar({ nickName, aim, imgUrl }) {
  return (
    <SidebarContainer>
      <Link to='/main'><h2>AKIVE</h2></Link>
      <hr/>
      <ul id='user-info-list'>
        <li className='user-info-item' style={{ textAlign:'right' }}>
          <Link to='modify-profile'><img id='modify-icon' src={ModifyIcon} alt='modify-icon'/></Link>
        </li>
        <li className='user-info-item'>
          <img id='profile-icon' src={imgUrl} alt='profile img'/>
        </li>
        <li className='user-info-item'>
          <p id='nickname'>{nickName}</p>
        </li>
        <li className='user-info-item'>
          <p id='aim'>{aim}</p>
        </li>
      </ul>
      <hr/>
      <ul id='menu-list'>
        <Link className='menu-item' to='/main'>
          <MenuItem icon={HomeIcon}>메인</MenuItem>
        </Link>
        <Link className='menu-item' to='planner/sessional-plan'>
          <MenuItem icon={PlannerIcon}>플래너</MenuItem>
        </Link>
        <Link className='menu-item' to='report/list'>
          <MenuItem icon={ReportIcon}>리포트</MenuItem>
        </Link>
        <Link className='menu-item' to='daily-log/list'>
          <MenuItem icon={DailyLogIcon}>데일리 로그</MenuItem>
        </Link>
        <Link className='menu-item' to='study-setting'>
          <MenuItem icon={SettingIcon}>스터디 설정</MenuItem>
        </Link>
      </ul>
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div`
  flex: 1;
  background-color: ${props => props.theme.colors.primary};
  text-align: center;
  color: white;
  
  hr { width: 90% }

  a:visited, a:active, a:link { color: white }

  li::marker {  font-size: 1.8em; }

  .user-info-item { list-style-type: none; }
  
  .menu-item { 
    font-size: 1em;
    & li { 
      padding-bottom: 10px;
      padding-inline-start: 3ch;
    }
  }
  
  #user-info-list {
    height: 30%;
    margin: 20px;
    padding: 0;
  }
  
  #nickname { font-size: 1em }
  
  #aim { font-size: 0.8em }

  #profile-icon {
    border-radius: 50%;
    width: 110px;
    height: 110px;
  }
  
  #modify-icon {
    width: 10%;
    text-align: right;
    cursor: pointer;
  }

  #menu-list {
    padding-inline-start: 5ch;
    text-align: left;
    font-size: 1.3em;
  }
`

const MenuItem = styled.li`
  list-style-image: url(${props => props.icon});
`

export default Sidebar