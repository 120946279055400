import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'
import { CompleteBtn } from '../../Buttons'
import {BasicPwInputForForm} from '../../InputBoxes'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'

export default function ModifyPwModal({ nickname }) {
  const { closeModal, openModal } = useModal()
  const { control, handleSubmit, getValues } = useForm()
  const navigate = useNavigate()

  async function ModifyPw(data) {
    api.patch(`/users/${nickname}/passwords`, { ..._.omit(data, 'check_new_pw') })
      .then(result => {
        closeModal()
        openModal({ type: 'alert', props: { isError: false, contents: '비밀번호가 변경되었습니다.' } })
        navigate('/sign-in')
      })
      .catch((err) => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message } }))
  }

  return (
    <>
      <S.Background/>
      <S.ModalBox width='350px' height='420px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>비밀번호 변경</S.Title>
          <BasicPwInputForForm
            control={control}
            label='현재 비밀번호'
            name='current_pw'
            style={{ marginTop: '10px', marginBottom: '20px' }}
            minWidth='100%'
            rules={{ required: '현재 비밀번호를 입력해 주세요' }}
          />
          <BasicPwInputForForm
            control={control}
            label='새 비밀번호'
            name='new_pw'
            style={{ marginBottom: '20px' }}
            minWidth='100%'
            rules={{ 
              required: '새 비밀번호를 입력해 주세요',
              pattern: {
                value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=!]).{8,}$/,
                message: `숫자와 영문, 특수문자 조합 \n 8자 이상이어야 합니다`,
              }
            }}
          />
          <BasicPwInputForForm
            control={control}
            label='새 비밀번호 확인'
            name='check_new_pw'
            minWidth='100%'
            rules={{
              required: '새 비밀번호 확인을 입력해 주세요',
              validate: v => v === getValues('new_pw') || '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
            }}
          />
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(ModifyPw)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}