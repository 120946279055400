import { createGlobalStyle } from 'styled-components'
import './font.css'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR Regular', sans-serif;
  }
  
  a { text-decoration: none; }

  a:visited, a:active, a:link { color: black }
`
export default GlobalStyle