import {useEffect, useState} from 'react'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'
import { UpdateAttendanceBtn } from '../../Buttons'
import { DailyLogTable } from '../../Tables'

export default function GetDailyLogModal({ date }) {
  const { closeModal } = useModal()
  const [attendanceInfo, setAttendanceInfo] = useState({})
  const [dailyLog, setDailyLog] = useState([])

  useEffect(() => {
    api.get(`/attendances/${date}`).then(result => setAttendanceInfo(result.data.img_url))
    api.get(`daily-logs/${date}`).then(result => setDailyLog(result.data.daily_log))
  }, [])

  return (
    <>
      <S.Background />
      <S.ModalBox max_height='600px' min_height='400px' width='600px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        {/*출석 인증*/}
        <UpdateAttendanceBtn
          attendanceInfo={attendanceInfo}
          setAttendanceInfo={setAttendanceInfo}
          date={date}
          height='200px'
          width='260px'
        />
        {/*오늘의 데일리(일일) 로그*/}
        <DailyLogTable
          dailyLog={dailyLog}
          date={date}
          setDailyLog={setDailyLog}
        />
      </S.ModalBox>
    </>
  )
}