import React, { useState, useRef, useEffect  } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import api from '../../services/api'
import { setCookie } from '../../utils/manageCookie'
import useModal from '../../hooks/useModal'

import {
  AuthContainer,
  AuthBox,
  Title,
  FindList,
  FindItem
} from '../../components/Auth'
import { CompleteBtn } from '../../components/Buttons'
import { SingleLineInput } from '../../components/InputBoxes'

function SignIn () {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [inputs, setInputs] = useState({ email: '', pw: '', })

  const inputElement = useRef(null)
  useEffect(() => {
    if (inputElement.current) inputElement.current.focus()
  }, [])

  function onInputValChange (e) {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    })
  }

  async function reqSignIn () {
    const { email, pw } = inputs
    if (! email || ! pw) {
      openModal({ type: 'alert', props: { isError: true, contents: '이메일과 비밀번호 모두 입력하세요' }})
      return
    }

    try {
      const result = await api.post(`/users/login`, { email, pw })
      setCookie('akiveUser', result.data, 30, 'd')
      navigate('/main')
    } catch (e) {
      console.error(e)
      if (e.code === 'ERR_NETWORK') {
        openModal({ type: 'alert', props: { isError: true, contents: '잠시 후에 다시 시도해 주세요' }})
      } else {
        openModal({ type: 'alert', props: { isError: true, contents: '이메일 혹은 비밀번호를 다시 한번 확인해 주세요' }})
      }
    }
  }

  return (
    <AuthContainer>
      <AuthBox width='400px' height='300px'>
        <Title>Sign in to Akive</Title>
        <SingleLineInput
          id='email'
          type='email'
          width='100%'
          height='40px'
          placeholder='이메일을 입력하세요'
          ref={inputElement} onChange={onInputValChange}
        />
        <SingleLineInput
          id='pw'
          type='password'
          width='100%'
          height='40px'
          placeholder='비밀번호를 입력하세요'
          onChange={onInputValChange}
        />
        <CompleteBtn
          fontSize='1.2em'
          width='100%'
          height='50px'
          lineHeight= '50px'
          margin='30px 0 7px'
          onClick={reqSignIn}
        >Sign In
        </CompleteBtn>
        <FindList>
          <FindItem>
            <Link to='/email-auth?purpose=find-pw'>비밀번호를 잊었나요?</Link>
          </FindItem>
          <FindItem>
            <Link to='/email-auth?purpose=join'>아직 회원이 아닌가요?</Link>
          </FindItem>
        </FindList>
      </AuthBox>
    </AuthContainer>
  )
}

export default SignIn