import { useContext, useEffect } from 'react'
import moment from 'moment'

import api from '../../services/api'
import { StaticsContext } from '../../contexts'

import * as S from './Main.styles'

import { ReactComponent as IntensiveIcon } from '../../assets/images/intensive_icon.svg'
import { ReactComponent as SunIcon } from '../../assets/images/sun_icon.svg'
import { ReactComponent as StudyIcon } from '../../assets/images/study_icon.svg'

export default function TodayStudy() {
  const date = moment().format('YYYY-MM-DD')
  const { statics, setStatics } = useContext(StaticsContext)

  useEffect(() => {
    api.get(`/reports/infos/performances/${date}`, {
      params: {
        type: 'daily',
      }
    }).then(result => setStatics(result.data.statics))
  }, [])

  return (
    <>
      <S.ContentsBox height='150px'>
        <SunIcon className='title-icon' width='30px'/><S.Title fontSize='1.3em'>오늘의 스터디</S.Title>
        <S.TodayStudyContainer>
          <S.FlexItemBox flex='4'>
            {/*달성률*/}
            {/*TODO Progressbar.jsx 컴포넌트 사용*/}
            <S.ProgressContents marginTop='10px'>
              <S.ItemName float='left' width='20%'>달성률</S.ItemName>
              <S.ProgressBar value={statics?.completion_rate || 0} max='100' />
              <S.ItemName float='left' width='5%'>{statics?.completion_rate || 0}%</S.ItemName>
            </S.ProgressContents>
            {/*집중도*/}
            <S.ProgressContents marginTop='70px'>
              <S.ItemName float='left' width='20%'>집중도</S.ItemName>
              <S.ProgressBar value={statics?.intensive_rate || 0} max='100' />
              <S.ItemName float='left' width='5%'>{statics?.intensive_rate || 0}%</S.ItemName>
            </S.ProgressContents>
          </S.FlexItemBox>
          {/*학습 시간*/}
          <S.StudyTimeBox id='study-time-box-left'>
            <StudyIcon className='title-icon' width='25%' height='90%'/>
            <S.ItemName fontSize='1.3em'>학습 시간</S.ItemName>
            <S.Time>{statics?.study_time || '00:00 - 00:00'}</S.Time>
          </S.StudyTimeBox>
          {/*집중 시간*/}
          <S.StudyTimeBox>
            <IntensiveIcon className='title-icon' width='25%' height='90%'/>
            <S.ItemName fontSize='1.3em'>집중 시간</S.ItemName>
            <S.Time>
              {String(statics?.total_intensive_time?.hour || '00').padStart(2, '0')}
              :{String(statics?.total_intensive_time?.min || '00').padStart(2, '0')}
            </S.Time>
          </S.StudyTimeBox>
        </S.TodayStudyContainer>
      </S.ContentsBox>
    </>
  )
}