import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useModal from '../../hooks/useModal'

import * as S from './Modal.styles'
import { CompleteBtn } from '../Buttons'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

export default function SelectReportTypeModal(props) {
  const { closeModal } = useModal()
  const [type, setType] = useState(props.typeList[0])
  const navigate = useNavigate()

  return (
    <>
      <S.Background/>
      <S.ModalBox width='350px' height='300px'>
        <S.ModalContents width='75%'>
          <S.Title>리포트 유형 선택</S.Title>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue={props.typeList[0]}
              onChange={(e) => setType(e.target.value)}
            >
              <FormControlLabel value={props.typeList[0]} control={<Radio />} label={props.typeList[0]} />
              <FormControlLabel value={props.typeList[1]} control={<Radio />} label={props.typeList[1]} />
            </RadioGroup>
          </FormControl>
          <CompleteBtn
            width='100%'
            height='40px'
            margin='20px 0 0 10px'
            fontSize='1em'
            onClick={() => {
              navigate(`main/report/write?type=${type}&date=${props.date}`)
              closeModal()
            }}
          >확인
          </CompleteBtn>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}