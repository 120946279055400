import _ from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'

import api from '../services/api'
import useModal from '../hooks/useModal'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { ReactComponent as AddIcon } from '../assets/images/crud/add_icon.svg'

export const CRUDTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  margin: 10px auto;
  width: 90%;
  
  .add-icon, .modify-icon {
    cursor: pointer;
    height: 1em;
    width: 1em;
    margin-left: 5px;
  }

  #daily-log-rows { cursor: pointer; }

  .modify-icon {
    display: none;
    position: absolute;
  }

  #todo-rows:hover .modify-icon { display: inline; }

  & td, th { 
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.theme.colors.lightGray};
  }
  
  & td { 
    border-bottom: none;
    height: 28px;
    font-size: 0.85em;
  }
  
  & th { 
    border-top: none;
    height: 20px;
  }

  & tr:nth-child(even) { background-color: ${props => props.theme.colors.skyblue} }
  
  & th:first-child, td:first-child { border-left: 0; }

  & th:last-child, td:last-child { border-right: 0; }
`

export function DailyLogTable({ dailyLog, setDailyLog, setStatics, date }) {
  const [contextMenu, setContextMenu] = useState(null)
  const { openModal } = useModal()

  function handleContextMenu(event, dl) {
    event.preventDefault()
    setContextMenu(
      ! contextMenu
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          dl,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    )
  }

  /**
   * 데일리 로그 삭제한다.
   */
  async function deleteDailyLog(deleteDailyLogIdx) {
    try {
      const deleteResult = await api.delete(`daily-logs/${date}/${deleteDailyLogIdx}`)
      setDailyLog(deleteResult.data.daily_log)

      if (setStatics) {
        api.get(`/reports/infos/performances/${date}`, {
          params: {
            type: 'daily',
          }
        }).then(result => { setStatics(result.data.statics) })
      }
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  const handleClose = () => { setContextMenu(null) }

  return (
    <>
      <CRUDTable>
        <tr>
          {
            ['시간', '집중 시간'].map(head => {
              return <th>{head}</th>
            })
          }
          <th>
            한 일
            <AddIcon
              className='add-icon'
              onClick={() =>
                openModal({
                  type: 'updateDailyLog',
                  props: {
                    isModify: false,
                    dailyLog,
                    setDailyLog,
                    setStatics,
                    date,
                  }
                })
              }
            />
          </th>
        </tr>
        {
          _.map(dailyLog, dl => {
            return (
              <tr id='daily-log-rows' onClick={(event) => handleContextMenu(event, dl)}>
                {/*시간*/}
                <td>
                  {dl.start_time.split(' ')[1]} ~ {dl.end_time.split(' ')[1]}
                </td>
                {/*집중 시간*/}
                <td>
                  {String(parseInt(dl.intensive_time / 3600)).padStart(2, '0')}
                  :{String(parseInt(dl.intensive_time % 3600 / 60)).padStart(2, '0')}
                  :{String(parseInt(dl.intensive_time % 3600 % 60)).padStart(2, '0')}
                </td>
                {/*한 일*/}
                <td>{dl.contents}</td>
              </tr>
            )
          })
        }
        {/*일일 로그 수정 및 삭제 메뉴(context menu)*/}
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem
            onClick={() =>
              openModal({
                type: 'updateDailyLog',
                props: {
                  isModify: true,
                  dailyLog,
                  setDailyLog,
                  setStatics,
                  date,
                  modifyDailyLogIdx: contextMenu.dl.idx,
                }
              })
            }
          >
            <ListItemIcon>
              <EditIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>수정</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              openModal({
                type: 'confirm',
                props: {
                  contents: '정말로 삭제하시겠습니까?',
                  callbackFun: deleteDailyLog,
                  itemIdx: contextMenu.dl.idx,
                }
              })
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>삭제</ListItemText>
          </MenuItem>
        </Menu>
      </CRUDTable>
    </>
  )
}