/**
 * 쿠키를 브라우저에 저장한다.
 * @param cName 쿠키 이름
 * @param cValue 쿠키 값
 * @param exp 유효 시간 (default: 0)
 * @param unit 유효 시간 단위 (h: hour, d: day, default: m)
 */
function setCookie(cName, cValue, exp = 0, unit = 'm') {
  let time

  switch (unit) {
    case 'h':
      time = exp * 60 * 60
      break
    case 'd':
      time = exp * 60 * 60 * 24
      break
    default: time = exp * 60
  }

  const date = new Date()
  date.setTime(date.getTime() + time * 1000)
  document.cookie = `${cName}=${JSON.stringify(cValue)}; secure; samesite=strict; expires=${date.toUTCString()}; path=/;`
}

/**
 * 쿠키를 조회한다.
 * @param cName
 * @returns {string|null}
 */
function getCookie(cName) {
  const cValue = document.cookie.match(`(^|;) ?${cName}=([^;]*)(;|$)`)
  return cValue ? JSON.parse(cValue[2]) : null
}

/**
 * 쿠키를 삭제한다.
 * @param cName
 */
function deleteCookie(cName) {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`
}

export { setCookie, getCookie, deleteCookie }