import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

export default function BasicPagination({ totalPage = 1, onChange }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
      <Stack spacing={2}>
        <Pagination
          count={totalPage}
          color='primary'
          shape='rounded'
          showFirstButton
          showLastButton
          onChange={onChange}
        />
      </Stack>
    </div>
  )
}