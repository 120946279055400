import {useNavigate, useSearchParams} from 'react-router-dom'
import { useForm } from 'react-hook-form'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import {
  AuthContainer,
  AuthBox,
  Title,
} from '../../components/Auth'
import { BasicPwInputForForm } from '../../components/InputBoxes'
import { CompleteBtn } from '../../components/Buttons'

export default function FindPassword() {
  const { control, handleSubmit, getValues } = useForm()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const email = query.get('email')

  async function ModifyPw({ new_pw }) {
    api.patch(`/users/passwords`, { email, new_pw })
      .then(() => {
        openModal({ type: 'alert', props: { isError: false, contents: '비밀번호가 변경되었습니다.' } })
        navigate('/sign-in')
      })
      .catch((err) => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message } }))
  }

  return (
    <AuthContainer>
      <AuthBox width='400px' height='300px'>
        <Title>비밀번호 변경</Title>
        <BasicPwInputForForm
          control={control}
          label='새 비밀번호'
          name='new_pw'
          style={{ marginBottom: '30px' }}
          minWidth='100%'
          rules={{
            required: '새 비밀번호를 입력해 주세요',
            pattern: {
              value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=!]).{8,}$/,
              message: `숫자와 영문, 특수문자 조합 \n 8자 이상이어야 합니다`,
            }
          }}
        />
        <BasicPwInputForForm
          control={control}
          label='새 비밀번호 확인'
          name='check_new_pw'
          minWidth='100%'
          rules={{
            required: '새 비밀번호 확인을 입력해 주세요',
            validate: v => v === getValues('new_pw') || '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
          }}
          style={{ marginBottom: '30px' }}
        />
        <CompleteBtn
          fontSize='1em'
          height='40px'
          width='100%'
          onClick={handleSubmit(ModifyPw)}
        >확인
        </CompleteBtn>
      </AuthBox>
    </AuthContainer>
  )
}