import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import BasicPieChart from '../../components/charts/PieChart'
import BasicLineChart from '../../components/charts/LineChart'
import { BasicBtn, UpdateAttendanceBtn } from '../../components/Buttons'
import { ContentsBox, FlexItemBox } from '../../components/main/Main.styles'
import { ProgressBar } from '../../components/ProgressBar'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Input from '@mui/material/Input'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'

import { ReactComponent as ReportIcon } from '../../assets/images/menu/report_icon.svg'

export default function WriteReport() {
  const [attendanceInfo, setAttendanceInfo] = useState()
  const [etc, setEtc] = useState([''])
  const [goodPoints, setGoodPoints] = useState([''])
  const [improvePoints, setImprovePoints] = useState([''])
  const [statics, setStatics] = useState()
  const { openModal } = useModal()
  const [query, setQuery] = useSearchParams()
  const navigate = useNavigate()
  const type = query.get('type')
  const date = moment(query.get('date')).format('YYYY-MM-DD')

  useEffect(() => {
    try {
      api.get(`/attendances/${date}`).then(result => setAttendanceInfo(result.data))
      api.get(`/reports/infos/performances/${date}`
        , { params: { type } })
        .then(result => {
          const { statics, feedback } = result.data
          setStatics(statics)
          setGoodPoints([''])
          setImprovePoints([''])
          setEtc([''])

          if (! _.isEmpty(feedback)) {
            const tmpGoodPoints = []
            const tmpImprovePoints = []
            const tmpEtc = []

            _.forIn(feedback, (items, date) => {
              items.forEach(item => {
                tmpGoodPoints.push(..._.map(item.feedback.good_points, gp => `[${date}] ${gp}`))
              })

              items.forEach(item => {
                tmpImprovePoints.push(..._.map(item.feedback.improve_points, ip => `[${date}] ${ip}`))
              })

              items.forEach(item => {
                tmpEtc.push(..._.map(item.feedback.etc, etc => `[${date}] ${etc}`))
              })
            })

            setGoodPoints(tmpGoodPoints)
            setImprovePoints(tmpImprovePoints)
            setEtc(tmpEtc)
          }
        })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }, [date, type])

  async function handleDateChange(rawDate) {
    const selectedDate = moment(rawDate).format('YYYY-MM-DD')
    query.set('date', selectedDate)

    try {
      api.get(`/reports/infos/types/${selectedDate}`)
        .then(result => {
          const typeList = result.data.report_type_list
          if (typeList.length === 1) {
            query.set('type', typeList[0])
            setQuery(query)
          } else {
            openModal({ type: 'selectReportType', props: { typeList, date: selectedDate }})
          }
        })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  function handleKeyDown ({ key }, idx, state, setState) {
    if (key === 'Enter') {
      const newInputs = [...state]
      newInputs.splice(idx + 1, 0, '')
      setState(newInputs)
    }

    if (key === 'Backspace' || key === 'Delete') {
      if (state[idx].length === 0 && state.length > 1) {
        const newInputs = [...state]
        newInputs.splice(idx, 1)
        setState(newInputs)
      }
    }
  }

  function handleInputChange(e, idx, state, setState) {
    const newInputs = [...state]
    newInputs[idx] = e.target.value
    setState(newInputs)
  }

  return (
    <ContentsLayout>
      <ReportIcon width='35px' fill='black' style={{ verticalAlign: 'middle', marginRight: '10px' }}/>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          defaultValue={moment(date)}
          format='YYYY-MM-DD'
          slotProps={{ textField: { size: 'small' }}}
          sx={{ width: '150px'}}
          maxDate={moment()}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
      <span style={{ fontWeight: 'bold', fontSize: '1.5em', marginLeft: '10px' }}>{type} 리포트</span>
      <BasicBtn
        height='40px'
        width='120px'
        onClick={async () => {
          try {
            api.post(`/reports/${date}`,
              {
                report_type: type,
                feedback: {
                  good_points: goodPoints,
                  improve_points: improvePoints,
                  etc,
                }
              }
            ).then(result => {
              navigate(`../${result.data.idx}?type=${type}&date=${date}`)
            })
          } catch (err) {
            console.log(err)
            openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
          }
        }}
        style={{ position: 'absolute', right: 0 }}
      >완료
      </BasicBtn>
      <FlexContainer>
        <FlexContentsBox margin='10px 10px 0 0' width={type === 'daily' ? '80%' : '100%'}>
          <FlexContainer style={{ justifyContent: 'center' }}>
            <ul style={{ width: type === 'daily' ? '60%' : '100%', lineHeight: '2.5em', fontWeight: 'bold', marginTop: '10px'}}>
              <li style={{ verticalAlign: 'middle'}}>
                달성률
                <ProgressBar margin='0 10px 0 10px' max='100' value={statics?.completion_rate || 0} width='65%' height='15px' />
                <span style={{ fontSize: '0.8em' }}>{statics?.completion_rate}%</span>
              </li>
              <li>
                집중도
                <ProgressBar margin='0 10px 0 10px' max='100' value={statics?.intensive_rate || 0} width='65%' height='15px'/>
                <span style={{ fontSize: '0.8em' }}>{statics?.intensive_rate}%</span>
              </li>
              <li>총 {statics?.total_intensive_time?.hour}시간 {statics?.total_intensive_time?.min}분 학습 {type === 'daily' ? `(${statics?.study_time})` : ''} </li>
              {
                type === 'daily'
                ?
                  <li>
                    {
                      _.isEmpty(attendanceInfo)
                        ? '출석 인증 안 함'
                        :
                        attendanceInfo.is_late
                          ?
                          <>
                            <span style={{ color: 'red' }}>지각</span> [{moment(attendanceInfo.created_date).format('YYYY-MM-DD HH:mm:ss')}]
                          </>
                          : <>
                            <span style={{ color: 'blue' }}>출석</span> [{moment(attendanceInfo.created_date).format('YYYY-MM-DD HH:mm:ss')}]
                          </>
                    }
                  </li>
                :
                  <></>
              }
            </ul>
            {
              type === 'daily'
              ?
                <UpdateAttendanceBtn
                  attendanceInfo={attendanceInfo}
                  setAttendanceInfo={setAttendanceInfo}
                  date={date}
                  width='30%'
                />
              :
                <></>

            }
          </FlexContainer>
        </FlexContentsBox>
        {
          type !== 'daily'
          ?
            <FlexContentsBox margin='10px 10px 0 0' width='100%' height= '200px'>
              {
                <BasicLineChart
                  data={[
                    {
                      id: '학습 시간',
                      data: _.map(statics?.study_stats_by_period, v => ({ x: moment(v.date).format('MM-DD'), y: (v.study_time.hour) * 60 + (v.study_time.min) }))
                    },
                  ]}
                  max={_.every(statics?.study_stats_by_period, v => v.study_time.hour * 60 + v.study_time.min === 0) ? '100' : undefined}
                  yAxis='분'
                  margin={{ top: 20, right: 90, bottom: 30, left: 50 }}
                  colors='black'
                  isAxisBottom={!(type !== 'daily' && type !== 'weekly')}
                  pointSize={type !== 'daily' && type !== 'weekly' ? 0 : 5}
                />
              }
            </FlexContentsBox>
          :
          <></>
        }
        <FlexContentsBox margin='10px 0 0 0' width={type === 'daily' ? '50%' : '100%'} height= '200px' >
          {
            type === 'daily'
            ? <BasicPieChart
                data={_.map(statics?.time_tracking_statics, ts => ({ id: ts.log_type, value: ts.usage_rate }))}
                margin={{ top: 30, bottom: 30 }}
              />
            :
              <BasicLineChart
                data={[
                  {
                    id: '달성률',
                    data: _.map(statics?.study_stats_by_period, ss => ({ x: moment(ss.date).format('MM-DD'), y: ss.completion_rate }))
                  },
                  {
                    id: '집중도',
                    data: _.map(statics?.study_stats_by_period, ss => ({ x: moment(ss.date).format('MM-DD'), y: ss.intensive_rate }))
                  }
                ]}
                max='100'
                yAxis='%'
                margin={{ top: 20, right: 90, bottom: 30, left: 50 }}
                isAxisBottom={!(type !== 'daily' && type !== 'weekly')}
                pointSize={type !== 'daily' && type !== 'weekly' ? 0 : 5}
              />
          }
        </FlexContentsBox>
      </FlexContainer>
      <FlexContainer>
        <FlexContentsBox margin='10px 10px 0 0' width='100%' alignSelf='flex-start'>
          <ThumbUpOffAltIcon style={{ verticalAlign: 'middle', marginRight: '10px', width: '20px' }}/>
          <b>잘한점</b>
          {goodPoints.map((goodPoint, idx) => (
            <p style={{ textAlign: 'center'}}>
              {idx+1}.
              <Input
                id='good-point'
                key={idx}
                value={goodPoint}
                onKeyDown={(e) => handleKeyDown(e, idx, goodPoints, setGoodPoints)}
                onChange={(e) => handleInputChange(e, idx, goodPoints, setGoodPoints)}
                style={{ width: '90% '}}
              />
            </p>
          ))}
        </FlexContentsBox>
        <FlexContentsBox margin='10px 10px 0 0' width='100%' alignSelf='flex-start'>
          <ThumbDownOffAltIcon style={{ verticalAlign: 'middle', marginRight: '10px', width: '20px' }}/>
          <b>아쉬운점 → 본질적인 원인 → 해결책</b>
          {improvePoints.map((improvePoint, idx) => (
            <p style={{ textAlign: 'center'}}>
              {idx+1}.
              <Input
                id='improve-point'
                key={idx}
                value={improvePoint}
                onKeyDown={(e) => handleKeyDown(e, idx, improvePoints, setImprovePoints)}
                onChange={(e) => handleInputChange(e, idx, improvePoints, setImprovePoints)}
                style={{ width: '90% '}}
              />
            </p>
          ))}
        </FlexContentsBox>
        <FlexContentsBox margin='10px 0 0 0' width='100%' alignSelf='flex-start'>
          <InfoOutlinedIcon style={{ verticalAlign: 'middle', marginRight: '10px', width: '20px' }}/>
          <b>기타</b>
          {etc.map((item, idx) => (
            <p style={{ textAlign: 'center'}}>
              {idx+1}.
              <Input
                id='etc'
                key={idx}
                value={item}
                onKeyDown={(e) => handleKeyDown(e, idx, etc, setEtc)}
                onChange={(e) => handleInputChange(e, idx, etc, setEtc)}
                style={{ width: '90% '}}
              />
            </p>
          ))}
        </FlexContentsBox>
      </FlexContainer>
    </ContentsLayout>
  )
}

export const ContentsLayout = styled.div`
  margin: 20px;
  position: relative;
`

const FlexContainer = styled.div`
  display: flex;
`

const FlexContentsBox = styled(ContentsBox, FlexItemBox)`
  align-self: ${(props) => props.alignSelf};
  word-break:break-all;
`