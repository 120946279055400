const theme = {
  colors: {
    primary: '#001054',
    secondary: '#EB005C',
    gray: '#BABABA',
    lightGray: '#DADADA',
    skyblue: '#D7ECFF',
  }
}

export default theme