import * as S from '../Modal.styles'
import {ReactComponent as CloseIcon} from "../../../assets/images/close_delete_icon.svg";
import {BasicNumberInputForForm, BasicTextFieldForForm} from "../../InputBoxes";
import {CompleteBtn} from "../../Buttons";
import { useForm } from 'react-hook-form'
import useModal from "../../../hooks/useModal";

export default function UpdateQuantityModal(props) {
  const { control, handleSubmit } = useForm()
  const { closeModal } = useModal()

  return (
    <>
      <S.Background />
      <S.ModalBox height='350px' width='300px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>분량 및 메모 수정</S.Title>
          <BasicNumberInputForForm
            control={control}
            defaultValue={props?.quantity || 0}
            label='분량'
            name='quantity'
            maxWidth='50%'
            rules={{ required: '수정할 분량을 입력하세요', min: 0, max: props?.comletedQuantity }}
            style={{ marginTop: '10px' }}
          />
          <p style={{fontSize: '0.7em', color: 'red' }}>
            * 다른 날 혹은 주로부터 딜레이를 가져와서 계획량을 <br/> 늘릴 수 있습니다.
          </p>
          <BasicTextFieldForForm
            control={control}
            defaultValue={props?.memo || null}
            label='메모'
            name='memo'
            minWidth='100%'
            style={{ marginTop: '15px' }}
          />
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(props.callbackFun)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}