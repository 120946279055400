import _ from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../services/api'
import { deleteCookie } from '../../../utils/manageCookie'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'
import { CompleteBtn } from '../../Buttons'
import BasicSelect from '../../SelectBoxes'
import { TextField } from '@mui/material'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'

export default function LeaveModal({ nickname }) {
  const { closeModal, openModal } = useModal()
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [reason, setReason] = useState('사용하기 불편해요')
  const [reasonType, setReasonType] = useState('inconvenient')
  const navigate = useNavigate()
  const reasonList = [
    {
      type: 'inconvenient',
      nameKo: '사용하기 불편해요',
    },
    {
      type: 'lackingFunction',
      nameKo: '원하는 기능이 없어요',
    },
    {
      type: 'useless',
      nameKo: '서비스 필요성을 못느껴요',
    },
    {
      type: 'newAccount',
      nameKo: '새 계정을 만들고 싶어요',
    },
    {
      type: 'etc',
      nameKo: '기타',
    },
    {
      type: 'inPerson',
      nameKo: '직접 입력',
    }
  ]

  async function handleSubmit() {
    if (! reason?.trim()) {
      setIsError(true)
      setErrorMsg('탈퇴 사유를 입력해 주세요')
      return
    }

    if (reason.length > 300) {
      setIsError(true)
      setErrorMsg('300자를 초과할 수 없습니다')
      return
    }

    setIsError(false)
    setErrorMsg(null)

    try {
      await api.delete(`/users/${nickname}`, { params: { reason }})
      deleteCookie('akiveUser')
      closeModal()
      openModal({ type: 'alert', props: { isError: false, contents: '탈퇴되었습니다. 이용해주셔서 감사합니다.'}})
      navigate(`/`)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <S.Background/>
      <S.ModalBox width='350px' height='250px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='75%'>
          <p style={{ marginBottom: '30px' }}>탈퇴하시면 회원님의 모든 데이터는 복구가 불가능합니다. 이점 유의해 주세요.</p>
          {
            reasonType !== 'inPerson'
            ?
              <BasicSelect
                defaultValue='inconvenient'
                label='탈퇴 사유'
                name='leave_reason'
                itemList={reasonList}
                handleChange={(e) => {
                  setReasonType(e.target.value)
                  if (e.target.value === 'inPerson') {
                    setReason(null)
                  } else {
                    setReason(_.find(reasonList, ['type', e.target.value]).nameKo)
                  }
                }}
              />
            :
              <TextField
                error={isError}
                label='탈퇴 사유'
                sx={{ minWidth: '100%' }}
                size='small'
                autoFocus={true}
                onChange={(e) => setReason(e.target.value)}
                multiline
                rows={3}
                helperText={errorMsg}
              />
          }
          <CompleteBtn
            fontSize='1em'
            height='40px'
            width='100%'
            onClick={handleSubmit}
            style={{ marginTop: '30px'}}
          >확인
          </CompleteBtn>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}