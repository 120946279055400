import { useController } from 'react-hook-form'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

/**
 * 선택 메뉴 - 기본형
 */
export default function BasicSelect({ defaultValue, value, handleChange, itemList, label, style }) {
  return (
    <Box>
      <FormControl fullWidth size='small'>
        <InputLabel id='basic-select-label'>{label}</InputLabel>
        <Select
          defaultValue={defaultValue}
          value={value}
          label={label}
          labelId='basic-select-label'
          onChange={handleChange}
          style={style}
        >
          {
            itemList.map((item, key) => {
              return <MenuItem key={key} value={item.type}>{item.nameKo}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>
  )
}

/**
 * 폼 전용 선택 메뉴 - 기본형
 */
export function BasicSelectForForm({ control, defaultValue, itemList, label, name, rules, style }) {
  const { field } = useController({ control, name, rules, defaultValue })

  return (
    <Box>
      <FormControl fullWidth size='small'>
        <InputLabel id='basic-select-label'>{label}</InputLabel>
        <Select
          defaultValue={field.value}
          label={label}
          labelId='basic-select-label'
          onChange={field.onChange}
          style={style}
        >
          {
            itemList.map((item, key) => {
              return <MenuItem key={key} value={item.type}>{item.nameKo}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>
  )
}