import _ from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import {
  CompletionRateListContext,
  ExternalClickContext,
  StaticsContext,
} from '../../contexts'
import useModal from '../../hooks/useModal'

import * as S from './Main.styles'

import { CRUDTable } from '../Tables'

import { ReactComponent as CheckIcon } from '../../assets/images/check_icon.svg'
import { ReactComponent as ModifyIcon } from '../../assets/images/crud/edit_icon.svg'

export default function TodayTodo() {
  const date = moment().format('YYYY-MM-DD')
  const mounted = useRef(false)
  const { openModal } = useModal()
  const [todoList, setTodoList] = useState(null)
  const { setCompleteRateList } = useContext(CompletionRateListContext)
  const externalClickEvent = useContext(ExternalClickContext)
  const { setStatics } = useContext(StaticsContext)

  useEffect(() => {
    if (! mounted.current) {
      mounted.current = true
      try {
        api.get(`/sessional-plans/todos/${date}`).then(result =>  setTodoList(result.data.today_todo_list))
      } catch (err) {
        console.error(err)
        openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
      }
    } else {
      if (! externalClickEvent) return
      externalClickEvent.stopPropagation() // 이벤트 캡쳐링 방지
      if (! externalClickEvent.target.matches('input')) {
        todoList.forEach(todo => { todo.is_update = false })
        setTodoList(prevTodoList => [...prevTodoList])
      }
    }
  }, [externalClickEvent])

  /**
   * 오늘 할 일(계획) 달성량을 업데이트한다.
   * @param order 할 일 item order
   * @param spiIdx 세셔널 플랜 idx
   * @param tqIdx 세션 총량 idx
   * @param wqIdx 주간 계획량 idx
   * @param dqIdx 일일 계획량 idx
   * @param dailyQuantity 오늘 계획량
   * @param completedQuantity 오늘 계획 달성량
   * @returns {Promise<void>}
   */
  async function updateCompletedQuantity(order, spiIdx, tqIdx, wqIdx, dqIdx, dailyQuantity, completedQuantity) {
    try {
      if (completedQuantity < 0) {
        return openModal({ type: 'alert', props: { isError: true, contents: '0보다 크거나 같은 숫자만 허용됩니다' }})
      }

      if (dailyQuantity < completedQuantity) {
        return openModal({ type: 'alert', props: { isError: true, contents: '계획량보다 큰 값이 존재해선 안 됩니다' }})
      }

      await api.patch(`/sessional-plans/${spiIdx}/total-quantities/${tqIdx}/weekly-quantities/${wqIdx}/daily-quantities/${dqIdx}/completed-quantities`
        , { completed_quantity: completedQuantity })

      todoList[order].completed_quantity = completedQuantity
      setTodoList([...todoList])

      api.get(`/reports/infos/performances/${date}`, {
        params: {
          type: 'daily',
        }
      }).then(result => { setStatics(result.data.statics) })

      api.get(`/reports/infos/performances/completion-rates/${moment(date).format('YYYY')}`)
        .then(result => {
          setCompleteRateList(result.data.completion_rate_list)
        })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  /**
   * 계획 달성량 수정하기를 클릭 시 input 태그가 열리도록 한다.
   * @param order 오늘 할일 item 순서
   * @param event 클릭 event
   */
  function clickCompletedQuantityUpdate(order, event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation() // 이벤트 캡쳐링 방지
    todoList[order].is_update = true
    setTodoList([...todoList])
  }

  return (
    <>
      <S.FlexContentsBox margin='10px 0 0 0'>
        <CheckIcon className='title-icon' width='30px'/>
        <S.Title fontSize='1.3em'>오늘 할 일</S.Title>
        <Link className='shortcut-btn' to='planner'>
          위클리 플랜 바로 가기 >
        </Link>
        <CRUDTable>
          <tr>
            {
              ['분류', '영역', '단위', '계획', '완료'].map(head => {
                return <th>{head}</th>
              })
            }
          </tr>
          {
            _.map(todoList, (todo, order) => {
              return (
                <tr id='todo-rows'>
                  <td>{todo?.subject_category}</td>
                  <td>{todo?.subject}</td>
                  <td>{todo?.unit}</td>
                  <td>{todo?.daily_quantity}</td>
                  <td>
                    {
                      todo?.is_update
                        ?
                        <S.Input
                          defaultValue={todo?.completed_quantity}
                          type='number'
                          min='0'
                          max={todo?.daily_quantity}
                          onBlur={(event) => updateCompletedQuantity(order, todo.spi_idx, todo.tq_idx
                            , todo.wq_idx, todo.dq_idx, todo.daily_quantity
                            , event.target.value)}
                          autoFocus
                        />
                        :
                        <>
                          {todo?.completed_quantity}
                          <ModifyIcon className='modify-icon' fill='black' onClick={(event) => clickCompletedQuantityUpdate(order, event)}/>
                        </>
                    }
                  </td>
                </tr>
              )
            })
          }
        </CRUDTable>
      </S.FlexContentsBox>
    </>
  )
}