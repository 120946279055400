import styled from 'styled-components'

export const ContentsLayout = styled.div`
  margin: 20px;
  
  .title-icon {
    float: left;
    margin-right: 10px;
  }
`

export const ContentsBox = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  position: relative;
  height:  ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin};
  word-break:break-all;
`

export const FlexContainer = styled.div`
  display: flex;
`

export const FlexItemBox = styled.div`
  flex: ${props => props.flex || 1};
`

export const FlexContentsBox = styled(ContentsBox, FlexItemBox)`
  align-self: flex-start;
  
  a:visited, a:active, a:link { color: ${props => props.theme.colors.lightGray} }

  .shortcut-btn {
    font-size: 0.8em;
    position: absolute;
    right: 24px;
    top: 24px;
  }
`

export const Input = styled.input`
  height: 20px;
  width: 80%;
  text-align: center;
`

export const ProgressContents = styled.div`
  margin-top: ${(props => props.marginTop)}
`

export const ProgressBar = styled.progress`
  appearance: none;
  border-radius: 10px;
  height: 20px;
  width: 60%;
  float: left;
  margin: 0 10px 0 40px;
  
  &::-webkit-progress-bar {
    background: ${props => props.theme.colors.lightGray};
    border-radius:10px;
  }
  
  &::-webkit-progress-value {
    border-radius: 10px;
    background: ${props => props.theme.colors.secondary};
  }
`

export const StudyTimeBox = styled(FlexItemBox)`
  border-color: ${props => props.theme.colors.gray};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  text-align: center;
  
  &#study-time-box-left { margin-right: 30px; }
`

export const Title = styled.p`
  font-size: ${(props => props.fontSize)};
  font-weight: bold;
  margin: 0;
  text-align: ${(props => props.textAlign)};
  
`

export const ItemName = styled(Title)`
  float: ${(props => props.float)};
  width:  ${(props => props.width)},
`

export const TodayStudyContainer = styled(FlexContainer)`
  height: 70%;
  width: 100%;
  margin-top: 20px;
`

export const Time = styled.p`
  font-size: 1em;
  margin-bottom: 0;
`

export const YearlyStudyTable = styled.table`
  table-layout: fixed;
  margin: 32px auto;
  
  & tr {
    border-style: solid;
    border-width: 1px;
    width: 16px;
    height: 16px;
    border-color: ${props => props.theme.colors.primary};
  }
  
  .day-of-week-eng-td, .month-eng-td {
    color: ${props => props.theme.colors.gray};
    font-size: 9px;
    font-weight: bold;
  }
  
  .completion-rate-td {
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    height: 16px;
    width: 16px;
  }
`