import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import moment from 'moment'

import api from '../services/api'
import { getCookie } from '../utils/manageCookie'
import useModal from '../hooks/useModal'

import { CompleteBtn } from '../components/Buttons'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { TimeField } from '@mui/x-date-pickers/TimeField'

function StudySetting() {
  const { nickname } = getCookie('akiveUser')
  const { openModal } = useModal()
  const date = moment().format('YYYY-MM-DD')
  const { control, handleSubmit } = useForm({
    defaultValues: async () => api.get(`/users/${nickname}/goals`)
      .then(result =>{
        return ({
          attendance_time: moment(`${date} ${result.data.attendance_time}`),
          meal_1time: moment(`${date} ${String(parseInt(result.data.meal_1time / 3600)).padStart(2, '0')}:${String(parseInt(result.data.meal_1time % 3600 / 60)).padStart(2, '0')}`),
          rest_10min: parseInt(result.data.rest_10min / 60),
          meal_num: result.data.meal_num,
        })
      })
      .catch((err) => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message } })),
  })

  async function modifyStudySetting({ attendance_time, rest_10min, meal_num, meal_1time }) {
    meal_1time = moment(meal_1time).format('HH:mm').split(':')
    meal_1time = (meal_1time[0] * 3600) + (meal_1time[1] * 60)

    api.put(`/users/${nickname}/goals`, {
      attendance_time: moment(attendance_time).format('HH:mm:ss'),
      rest_10min: rest_10min * 60,
      meal_num,
      meal_1time,
    })
    .then(() => openModal({ type: 'alert', props: { isError: false, contents: '수정되었습니다.' } }))
    .catch((err) => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message } }))
  }

  return (
    <>
      <Container>
        <Box width='35%' height='40%'>
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name='attendance_time'
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    format='HH:mm'
                    inputRef={field.ref}
                    label='출석 인증 시간'
                    onChange={field.onChange}
                    sx={{ width: '45%' }}
                  />
                )}
              />
            </LocalizationProvider>
            <Controller
              control={control}
              name='rest_10min'
              rules={{ required: '휴식 시간(분)을 입력하세요', min: { value: 0, message: '0보다 작을 수 없습니다', }, max: { value: 10, message: '10분을 초과할 수 없습니다'} }}
              render={({ field, fieldState }) => (
                <TextField
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  value={field.value}
                  defaultValue={2}
                  inputRef={field.ref}
                  label='학습 10분 당 휴식 시간(분)'
                  onChange={field.onChange}
                  type='number'
                  sx={{ width: '45%' }}
                  style={{ marginLeft: '15px'}}
                />
              )}
            />
          </div>
          <div style={{ marginTop: '50px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                name='meal_1time'
                render={({ field }) => (
                  <TimeField
                    value={field.value}
                    format='HH:mm'
                    inputRef={field.ref}
                    label='회당 식사 시간'
                    onChange={field.onChange}
                    sx={{ width: '45%' }}
                  />
                )}
              />
              <Controller
                control={control}
                name='meal_num'
                rules={{ required: '식사 횟수를 입력하세요', min: { value: 0, message: '0보다 작을 수 없습니다', }, max: { value: 10, message: '10회를 초과할 수 없습니다'} }}
                render={({ field, fieldState }) => (
                  <TextField
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    value={field.value}
                    defaultValue={2}
                    inputRef={field.ref}
                    label='식사 횟수'
                    onChange={field.onChange}
                    type='number'
                    sx={{ width: '45%' }}
                    style={{ marginLeft: '15px'}}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <CompleteBtn
            onClick={handleSubmit(modifyStudySetting)}
            height='40px'
            width='120px'
            style={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)' }}
          >수정하기</CompleteBtn>
        </Box>
      </Container>
    </>
  )
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`

export const Box = styled.div`
  position: relative;
  width: ${props => props.width};
  height:  ${props => props.height};
  background-color: white;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`

export default StudySetting
