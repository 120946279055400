import _ from 'lodash'
import { useEffect , useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import * as S from './Planner.styles.jsx'
import BasicSelect from '../../components/SelectBoxes'
import { BasicBtn } from '../../components/Buttons'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'

import { ReactComponent as AddIcon } from '../../assets/images/crud/add_icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar_icon.svg'

export default function SessionalPlan() {
  const { closeModal, openModal } = useModal()
  const [sessionalPlan, setSessionalPlan] = useState()
  const [currentSessionalPlanIdx, setCurrentSessinalPlanIdx] = useState()
  const [sessionalPlanList, setSessionalPlanList] = useState([])
  const [sessionalPlanDate, setSessionalPlanDate] = useState()
  const [anchorEl, setAnchorEl] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      api.get(`/sessional-plans/lists`).then(result => {
        const { sessional_plan_list } = result.data
        setSessionalPlanList(sessional_plan_list)
        const latest = _.head(sessional_plan_list)
        setCurrentSessinalPlanIdx(latest.idx)
        setSessionalPlanDate(`${latest.session_start_date} ~ ${latest.session_end_date}`)
      })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }, [])


  useEffect(() => {
    try {
      if (! currentSessionalPlanIdx) return
      api.get(`/sessional-plans/${currentSessionalPlanIdx}`).then(result => {
        const { session_start_date, session_end_date } = result.data.sessional_plan_info
        setSessionalPlanDate(`${session_start_date} ~ ${session_end_date}`)
        setSessionalPlan(result.data)
      })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }, [currentSessionalPlanIdx, sessionalPlanList])

  function handleSessionalPlanChange(e) { setCurrentSessinalPlanIdx(e.target.value) }

  function clickMoreVert(e) { setAnchorEl(e.currentTarget) }

  function closeMenu() { setAnchorEl(null) }

  async function deleteSessionalPlan() {
    try {
      await api.delete(`/sessional-plans/${currentSessionalPlanIdx}`)
        .then(result => {
          const { sessional_plan_list } = result.data
          setSessionalPlanList(sessional_plan_list)
          setCurrentSessinalPlanIdx(_.head(sessional_plan_list).idx)
        })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  async function deleteSubject(totalQuantityIdx) {
    try {
      await api.delete(`/sessional-plans/${currentSessionalPlanIdx}/total-quantities/${totalQuantityIdx}`).then(result => setSessionalPlan(result.data))
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  const [contextMenu, setContextMenu] = useState(null)

  const handleClose = () => { setContextMenu(null) }

  function handleContextMenu(event, subject) {
    event.preventDefault()
    setContextMenu(
      ! contextMenu
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          subject,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    )
  }
  function drag(e) {
    e.dataTransfer.setData('weeklyQuantity', e.target.id)
  }

  function drop(e) {
    e.preventDefault()
    const target = JSON.parse(e.target.id)
    const weeklyQuantity = JSON.parse(e.dataTransfer.getData('weeklyQuantity'))
    openModal({
      type: 'updateWeeklyDelay',
      props: {
        sessionalPlanIdx: currentSessionalPlanIdx,
        totalQuantityIdx: weeklyQuantity.tq_idx,
        weeklyQuantityIdx: weeklyQuantity.idx,
        weeklyDelay: weeklyQuantity.weekly_delay,
        targetWeeklyQuantityIdx: target.idx,
        targetWeekNum: target.week_num,
        sessionalPlan,
        setSessionalPlan,
      }
    })
  }

  return (
    <div style={{ margin: '30px', position: 'relative' }}>
      <h2>Sessional Plan</h2>
      <BasicBtn
        height='40px'
        width='120px'
        onClick={() => openModal({ type: 'updateSessionalPlan', props: { setSessionalPlanList, setCurrentSessinalPlanIdx } })}
        style={{ position: 'absolute', right: '0px', top: '0px'  }}
      >세셔널 플랜 추가
      </BasicBtn>
      {/* 세셔널 플랜 상단 부분 - 날짜, 세셔널 플랜 추가 및 선택 */}
      {
        sessionalPlanList.length
          ?
          <div style={{ display: 'flex' }}>
            <CalendarIcon/>
            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{sessionalPlanDate}</span>
            <div style={{ display: 'flex', position: 'absolute', right: '0' }}>
              <BasicSelect
                defaultValue={currentSessionalPlanIdx}
                value={currentSessionalPlanIdx}
                handleChange={handleSessionalPlanChange}
                itemList={
                  _.map(sessionalPlanList, sessionalPlan => {
                    return {
                      type: sessionalPlan.idx,
                      nameKo: sessionalPlan.session_name,
                    }
                  })
                }
                name='sessional_plan_list'
                style={{ width: '250px', height: '32px', textAlign: 'center' }}
              />
              <MoreVertIcon
                style={{ cursor: 'pointer'}}
                onClick={clickMoreVert}
              />
              <Menu
                id='fade-menu'
                anchorEl={anchorEl}
                MenuListProps={{ 'aria-labelledby': 'fade-button' }}
                onClose={closeMenu}
                open={Boolean(anchorEl)}
              >
                <MenuItem
                  onClick={() =>  {
                    closeMenu()
                    openModal({
                      type: 'updateSessionalPlan',
                      props: {
                        sessionalPlanIdx: currentSessionalPlanIdx,
                        sessionalPlan,
                        setSessionalPlanList,
                        setCurrentSessinalPlanIdx
                      }
                    })
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>수정</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    closeMenu()
                    openModal({
                      type: 'confirm',
                      props: {
                        contents: '정말로 삭제하시겠습니까?',
                        callbackFun: deleteSessionalPlan,
                      }
                    })
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>삭제</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </div>
          : <></>
      }
      {/* 세셔널 플랜 본문 부분 */}
      <div style={{ marginTop: '30px', overflowX: 'scroll' }}>
        <S.Planner>
          {/* 헤더 - 분류, 영역, session 분량, 주 차 */}
          <tr>
            <S.MainHeader rowSpan='2' width='50px'>분류</S.MainHeader>
            <S.MainHeader rowSpan='2'>
              <span style={{ verticalAlign: 'middle' }}>영역</span>
              <AddIcon
                className='add-icon'
                onClick={() =>
                  openModal({
                    type: 'updateSubject',
                    props: {
                      sessionalPlanIdx: currentSessionalPlanIdx,
                      sessionalPlan,
                      setSessionalPlan,
                    }
                  })
                }
              />
            </S.MainHeader>
            <S.MainHeader rowSpan='2'>session 분량</S.MainHeader>
            {
              _.map(sessionalPlan?.week_num, wn => {
                return (
                  <S.MainHeader
                    colSpan='3'
                    fontSize='0.75em'
                    width='170px'
                    height='25px'
                    onClick={() => navigate(`${currentSessionalPlanIdx}/weekly-plan?week_start_date=${moment(wn.week_start_date).format('YYYY-MM-DD')}&week_end_date=${moment(wn.week_end_date).format('YYYY-MM-DD')}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    {wn.week_num}주 차 &nbsp;
                    ({moment(wn.week_start_date).format('MM/DD')} ~ {moment(wn.week_end_date).format('MM/DD')})
                  </S.MainHeader>
                )
              })
            }
          </tr>
          {/* 헤더 - 주 차별 분량, 완료, 딜레이 */}
          <tr>
            {
              _.map(sessionalPlan?.week_num, () => {
                return (
                  <>
                    <S.SubHeader>분량</S.SubHeader>
                    <S.SubHeader>완료</S.SubHeader>
                    <S.SubHeader style={{ backgroundColor: '#FFC8E2' }}>딜레이</S.SubHeader>
                  </>
                )
              })
            }
          </tr>
          {
            _.entries(_.groupBy(sessionalPlan?.total_quantity, 'subject_category'))
              .map(([subjectCategory,subjectList]) => {
                return (
                  _.map(subjectList, (s, i) => {
                    return (
                      <tr>
                        {/* 분류 */}
                        {
                          i === 0
                            ? <S.TotalPlanData className='update-subject' rowSpan={subjectList.length}  onClick={(event) => handleContextMenu(event, s)}>{subjectCategory}</S.TotalPlanData>
                            :  <></>
                        }
                        {/* 영역 */}
                        <S.TotalPlanData className='update-subject'  onClick={(event) => handleContextMenu(event, s)}>{s.subject}</S.TotalPlanData>
                        {/* session 분량 */}
                        <Tooltip
                          title={s.memo}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -25],
                                  },
                                },
                              ],
                            },
                          }}>
                          <S.TotalPlanData className='update-subject'  onClick={(event) => handleContextMenu(event, s)}>{`${s.total_quantity}${s.unit}`}</S.TotalPlanData>
                        </Tooltip>
                        {/* 주 차별 계획 양, 완수 양, 딜레이 양 */}
                        {
                          _.map(s.weekly_quantity, (wq, idx) => {
                            return (
                              <>
                                <Tooltip
                                  title={wq.memo}
                                  arrow
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: 'offset',
                                          options: {
                                            offset: [0, -25],
                                          },
                                        },
                                      ],
                                    },
                                }}>
                                  <S.Data
                                    id={`{ "idx": ${wq.idx}, "week_num": ${idx+1} }`}
                                    onClick={() => {
                                      openModal({
                                        type: 'updateQuantity',
                                        props: {
                                          quantity: wq.weekly_quantity,
                                          completedQuantity: wq.completed_quantity,
                                          memo: wq.memo,
                                          callbackFun: async (data) => {
                                            try {
                                              await api.put(`sessional-plans/${currentSessionalPlanIdx}/total-quantities/${wq.tq_idx}/weekly-quantities/${wq.idx}`, {
                                                reduced_weekly_quantity: data.quantity,
                                                memo: data.memo,
                                              }).then(result => {
                                                sessionalPlan.total_quantity.forEach(tq => {
                                                  if (tq.idx === wq.tq_idx) tq.weekly_quantity = result.data.weekly_quantity
                                                })
                                                setSessionalPlan(prev => ({ ...prev }))
                                                closeModal()
                                              })
                                            } catch (err) {
                                              console.error(err)
                                              openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
                                            }
                                          },
                                        }
                                      })
                                    }}
                                    onDrop={drop}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {wq.weekly_quantity}
                                  </S.Data>
                                </Tooltip>
                                <S.Data>{wq.weekly_completed_quantity}</S.Data>
                                <S.Data
                                  id={`{ "idx": ${wq.idx}, "tq_idx": ${wq.tq_idx}, "weekly_delay": ${wq.weekly_delay} }`}
                                  draggable={true}
                                  onDragStart={drag}
                                  style={{ backgroundColor: '#FFEFF5', cursor: 'move' }}
                                >
                                  {wq.weekly_delay}
                                </S.Data>
                              </>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                )
              })
          }
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={() => {
                handleClose()
                openModal({
                  type: 'updateSubject',
                  props: {
                    sessionalPlanIdx: currentSessionalPlanIdx,
                    totalQuantity: _.filter(sessionalPlan.total_quantity, { idx: contextMenu.subject.idx })[0],
                    sessionalPlan,
                    setSessionalPlan,
                  }
                })
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize='small'/>
              </ListItemIcon>
              <ListItemText>수정</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                openModal({
                  type: 'confirm',
                  props: {
                    contents: '정말로 삭제하시겠습니까?',
                    callbackFun: deleteSubject,
                    itemIdx: contextMenu.subject.idx,
                  }
                })
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize='small'/>
              </ListItemIcon>
              <ListItemText>삭제</ListItemText>
            </MenuItem>
          </Menu>
        </S.Planner>
      </div>
    </div>
  )
}