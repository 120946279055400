import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import {
  AuthContainer,
  AuthBox,
  Title,
} from '../../components/Auth'
import { CompleteBtn } from "../../components/Buttons"
import { TextField } from "@mui/material"

export default function EmailAuth() {
  const [email, setEmail] = useState(null)
  const [isInValidEmail, setIsInValidEmail] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState(null)
  const [isSend, setIsSend] = useState(false)
  const [isInvalidNumber, setIsInvalidNumber] = useState(false)
  const [numberErrorMsg, setNumberErrorMsg] = useState(null)
  const [number, setNumber] = useState(null)
  const [timeLeft, setTimeLeft] = useState(null)
  const { openModal } = useModal()
  const [query] = useSearchParams()
  const purpose = query.get('purpose')
  const navigate = useNavigate()
  const INTERVAL = 1000
  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0')
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0')


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL)
    }, INTERVAL)

    if (timeLeft <= 0) clearInterval(timer)

    return () => {
      clearInterval(timer)
    }
  }, [timeLeft])


  async function sendAuthNumber() {
    if (! email?.trim().length) {
      setIsInValidEmail(true)
      setEmailErrorMsg('이메일을 입력해 주세요')
      return
    }

    if (! /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/.test(email)) {
      setIsInValidEmail(true)
      setEmailErrorMsg('올바른 이메일 형식이 아닙니다')
      return
    }

    api.post('/users/email-auth', { email, purpose })
      .then(() => {
        setTimeLeft(3 * 60 * 1000)
        setIsSend(true)
      })
      .catch((err) => {
        openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
      })
  }

  async function reqAuth() {
    if (! number?.trim().length) {
      setIsInvalidNumber(true)
      setNumberErrorMsg('인증 번호를 입력해 주세요')
      return
    }

    api.get('/users/email-auth', { params: { email, purpose, auth_num: number, }})
      .then(() => {
        if (purpose === 'find-pw') return navigate(`/find-pw?email=${email}`)
        if (purpose === 'join') return navigate(`/sign-up?email=${email}`)
      })
      .catch(err => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }}))
  }

  return (
    <AuthContainer>
      <AuthBox width='400px' height='300px'>
        <Title>이메일 인증</Title>
        <div style={{ textAlign: 'center' }}>
          <TextField
            error={isInValidEmail}
            type='email'
            size='small'
            label='이메일'
            helperText={emailErrorMsg}
            onChange={(e) => {
              setIsInValidEmail(false)
              setEmailErrorMsg(null)
              setEmail(e.target.value)
            }}
            autoFocus
          />
          <CompleteBtn
            onClick={sendAuthNumber}
            height='40px'
            width='130px'
            style={{ marginLeft: '10px' }}
          >인증 번호 전송
          </CompleteBtn>
        </div>
        {
          isSend
            ?
            <>
              <div style={{ textAlign: 'center', marginTop: '30PX' }}>
                <TextField
                  label='인증 번호'
                  error={isInvalidNumber}
                  size='small'
                  onChange={(e) => {
                    setIsInvalidNumber(false)
                    setNumberErrorMsg(null)
                    setNumber(e.target.value)
                  }}
                  helperText={numberErrorMsg}
                  autoFocus
                />
                <CompleteBtn onClick={reqAuth} height='40px' width='130px' style={{ marginLeft: '10px' }}>인증하기</CompleteBtn>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginTop: '30px' }}>{minutes} : {second}</div>
              </div>
            </>
            :
            <></>
        }
      </AuthBox>
    </AuthContainer>
  )
}