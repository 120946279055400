import { createSlice } from '@reduxjs/toolkit'

export const modalSelector = (state) => state.modal

const initialState = []
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { type, props } = action.payload
      return state.concat({ type, props })
    },
    closeModal: (state, action) => {
      state.pop()
    }
  }
})

export const { openModal, closeModal } = modalSlice.actions