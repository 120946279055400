import _ from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'

import api from '../../services/api'
import { ExternalClickContext } from '../../contexts'
import useModal from '../../hooks/useModal'

import * as S from './Planner.styles'
import Tooltip from '@mui/material/Tooltip'
import { SmallNumberInput } from '../../components/InputBoxes'

export default function WeeklyPlan() {
  const externalClickEvent = useContext(ExternalClickContext)
  const { openModal, closeModal } = useModal()
  const { spi_idx } = useParams()
  const mounted = useRef(false)
  const [query, setQuery] = useSearchParams()
  const [weeklyPlan, setWeeklyPlan] = useState()
  const week_start_date = query.get('week_start_date')
  const week_end_date = query.get('week_end_date')

  useEffect(() => {
    if (! mounted.current) {
      mounted.current = true
      try {
        api.get(`/sessional-plans/${spi_idx}/weekly-plans`
          , {params: {week_start_date, week_end_date}})
          .then(result => setWeeklyPlan(result.data))
      } catch (err) {
        console.error(err)
        openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
      }
    } else {
      if (! externalClickEvent) return
      externalClickEvent.stopPropagation() // 이벤트 캡쳐링 방지
      if (! externalClickEvent.target.matches('input')) {
        weeklyPlan.weekly_quantity.forEach(wq => {
          wq.daily_quantity.forEach(dq => dq.isModifyCompledQuantity = false)
        })
        setWeeklyPlan(prev => ({ ...prev }))
      }
    }
  }, [externalClickEvent])

  /**
   * 계획 달성량 수정하기를 클릭 시 input 태그가 열리도록 한다.
   */
  function clickCompletedQuantityUpdate(weeklyQuantityOrder, dailyQuantityOrder, e) {
    e.stopPropagation() // 이벤트 캡쳐링 방지
    weeklyPlan.weekly_quantity[weeklyQuantityOrder].daily_quantity[dailyQuantityOrder].isModifyCompledQuantity = true
    setWeeklyPlan({...weeklyPlan})
  }

  /**
   * 다른 영역 클릭 시 input 태그가 닫히도록 한다.
   */
  function endInput(e) {
    e.stopPropagation() // 이벤트 캡쳐링 방지
    if (! e.target.matches('input')) {
      weeklyPlan.weekly_quantity.forEach(wq => {
        wq.daily_quantity.forEach(dq => dq.isModifyCompledQuantity = false)
      })
      setWeeklyPlan({...weeklyPlan})
    }
  }

  function drag(e) {
    e.dataTransfer.setData('dailyQuantity', e.target.id)
  }

  function drop(e) {
    e.preventDefault()
    const target = JSON.parse(e.target.id)
    const dailyQuantity = JSON.parse(e.dataTransfer.getData('dailyQuantity'))
    openModal({
      type: 'updateDailyDelay',
      props: {
        sessionalPlanIdx: spi_idx,
        totalQuantityIdx: dailyQuantity.tq_idx,
        weeklyQuantityIdx: dailyQuantity.wq_idx,
        dailyQuantityIdx: dailyQuantity.idx,
        dailyDelay: dailyQuantity.daily_delay,
        targetDailyQuantityIdx: target.idx,
        targetDayOfWeek: target.dayOfWeek,
        weeklyPlan,
        setWeeklyPlan,
      }
    })
  }

  async function updateCompletedQuantity(subjectOrder, dqOrder, spiIdx, tqIdx, wqIdx, dqIdx, dailyQuantity, completedQuantity) {
    try {
      if (completedQuantity < 0) {
        return openModal({ type: 'alert', props: { isError: true, contents: '0보다 크거나 같은 숫자만 허용됩니다' }})
      }

      if (dailyQuantity < completedQuantity) {
        return openModal({ type: 'alert', props: { isError: true, contents: '계획량보다 큰 값이 존재해선 안 됩니다' }})
      }

      await api.patch(`/sessional-plans/${spiIdx}/total-quantities/${tqIdx}/weekly-quantities/${wqIdx}/daily-quantities/${dqIdx}/completed-quantities`
        , { completed_quantity: completedQuantity })
        .then(result => {
          weeklyPlan.weekly_quantity[subjectOrder].daily_quantity[dqOrder] = result.data
          setWeeklyPlan({...weeklyPlan})
        })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  return (
    <div style={{ margin: '30px', position: 'relative' }} onClick={(e) => endInput(e)}>
      <h2>Weekly Plan</h2>
      <span
        style={{
          fontWeight: 'bold',
          position: 'absolute',
          right: '0px',
          top: '0px',
        }}
      >
        {weeklyPlan?.date.week_num}주 차
        ({weeklyPlan?.date.week_start_date} / {weeklyPlan?.date.week_end_date})
      </span>
      {/* 세셔널 플랜 본문 부분 */}
      <div style={{ marginTop: '30px', overflowX: 'scroll' }}>
        <S.Planner>
          {/* 헤더 - 분류, 영역, session 분량, 주 차 */}
          <tr>
            <S.MainHeader rowSpan='2' width='50px'>분류</S.MainHeader>
            <S.MainHeader rowSpan='2'>
              <span style={{ verticalAlign: 'middle' }}>영역</span>
            </S.MainHeader>
            <S.MainHeader rowSpan='2'>week 분량</S.MainHeader>
            {
              _.map(weeklyPlan?.date.day_of_week_list, dayOfWeek  => {
                return (
                  <S.MainHeader
                    colSpan='3'
                    fontSize='0.75em'
                    width='170px'
                    height='25px'>
                    {dayOfWeek.day_of_week} &nbsp;
                    ({moment(dayOfWeek?.date).format('MM / DD')})
                  </S.MainHeader>
                )
              })
            }
          </tr>
          {/* 헤더 - 주 차별 분량, 완료, 딜레이 */}
          <tr>
            {
              _.map(weeklyPlan?.date.day_of_week_list, () => {
                return (
                  <>
                    <S.SubHeader>분량</S.SubHeader>
                    <S.SubHeader>완료</S.SubHeader>
                    <S.SubHeader style={{ backgroundColor: '#FFC8E2' }}>딜레이</S.SubHeader>
                  </>
                )
              })
            }
          </tr>
          {
            _.entries(_.groupBy(weeklyPlan?.weekly_quantity, 'subject_category'))
              .map(([subjectCategory,subjectList]) => {
                return (
                  _.map(subjectList, (subject, subjectOrder) => {
                    return (
                      <tr>
                        {/* 분류 */}
                        {
                          subjectOrder === 0
                            ? <S.TotalPlanData rowSpan={subjectList.length}>{subjectCategory}</S.TotalPlanData>
                            :  <></>
                        }
                        {/* 영역 */}
                        <S.TotalPlanData>{subject.subject}</S.TotalPlanData>
                        {/* week 분량 */}
                        <Tooltip
                          title={subject.wq_memo}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -25],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <S.TotalPlanData>{`${subject.weekly_quantity}${subject.unit}`}</S.TotalPlanData>
                        </Tooltip>
                        {/* 일별 계획 양, 완수 양, 딜레이 양 */}
                        {
                          _.map(subject.daily_quantity, (dq, dqOrder) => {
                            return (
                              <>
                                <Tooltip
                                  title={dq.dq_memo}
                                  arrow
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: 'offset',
                                          options: {
                                            offset: [0, -25],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  <S.Data
                                    id={`{ "idx": ${dq.dq_idx}, "dayOfWeek": "${_.filter(weeklyPlan?.date.day_of_week_list, dayOfWeek => dayOfWeek.date === dq.date)[0].day_of_week }" }`}
                                    onClick={() => openModal({
                                      type: 'updateQuantity',
                                      props: {
                                        quantity: dq.daily_quantity,
                                        completedQuantity: dq.completed,
                                        memo: dq.dq_memo,
                                        callbackFun: async (data) => {
                                            try {
                                              await api.put(`sessional-plans/${spi_idx}/total-quantities/${subject.tq_idx}/weekly-quantities/${subject.wq_idx}/daily-quantities/${dq.dq_idx}`
                                                , { reduced_daily_quantity: data.quantity, memo: data.memo })
                                                .then(result => {
                                                  weeklyPlan.weekly_quantity.forEach(wq => {
                                                    if (wq.wq_idx === subject.wq_idx) wq.daily_quantity = result.data.daily_quantity
                                                  })
                                                  setWeeklyPlan(prev => ({ ...prev }))
                                                  closeModal()
                                                })
                                            } catch (err) {
                                              console.error(err)
                                              openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
                                            }
                                          }
                                        }
                                      })
                                    }
                                    onDrop={drop}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {dq.daily_quantity}
                                  </S.Data>
                                </Tooltip>
                                <S.Data onClick={(e) => clickCompletedQuantityUpdate(subjectOrder, dqOrder, e)} style={{ cursor: 'pointer' }}>
                                  {
                                    dq.isModifyCompledQuantity
                                    ? <SmallNumberInput
                                        number={dq.completed_quantity}
                                        onBlur={(e) => updateCompletedQuantity(subjectOrder, dqOrder, spi_idx, subject.tq_idx, subject.wq_idx, dq.dq_idx, dq.daily_quantity, e.target.value)}
                                      />
                                    : dq.completed_quantity
                                  }
                                </S.Data>
                                <S.Data
                                  id={`{ "idx": ${dq.dq_idx}, "tq_idx": ${subject.tq_idx}, "wq_idx": ${subject.wq_idx}, "daily_delay": ${dq.delay_quantity} }`}
                                  draggable={true}
                                  onDragStart={drag}
                                  style={{ backgroundColor: '#FFEFF5', cursor: 'move' }}
                                >
                                  {dq.delay_quantity}
                                </S.Data>
                              </>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                )
              })
          }
        </S.Planner>
      </div>
    </div>
  )
}