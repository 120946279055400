import axios from 'axios'
import { getCookie, deleteCookie, setCookie } from '../utils/manageCookie'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
})

/**
 * API 요청 시마다 액세스 토큰을 실어 보내도록 한다.
 */
api.interceptors.request.use(
  function (config) {
    const user = getCookie('akiveUser')
    const pathList = config.url.split('/')
    if (user && pathList[pathList.length-1] !== 'tokens') config.headers.Authorization = `Bearer ${user.access_token}`
    return config
  }
)

/**
 * 정상 응답일 경우 응답을 그대로 리턴한다.
 * 단, 액세스 토큰이 만료 됐을 경우 리프레시 토큰을 통해 토큰을 재발행 하도록 한다.
 */
api.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const originalReq = error.config
    if (error.response && error.response.status === 401
      && error.response.data.message === '만료된 액세스 토큰입니다.') {
      try {
        // 액세스 토큰이 만료됐을 경우 리프레시 토큰을 통해 재발행
        let user = getCookie('akiveUser')
        const result = await api.post(`/users/${user.nickname}/tokens`
          , {}
          , { headers: { Authorization: `Bearer ${user.refresh_token}` }})

        if (result?.data) {
          user.access_token = result.data.access_token

          // 리프레시 토큰이 만료되기 1분 이내일 경우 서버에서는 리프레시 토큰도 자동으로 재발급 해줌
          if (result.data?.refresh_token) user.refresh_token = result.data.refresh_token
          originalReq.headers.Authorization = `Bearer ${user.access_token}`
          setCookie('akiveUser', user, 30, 'd')

          // 새로 발급 받은 토큰으로 원래 하려던 API 요청 시도
          return await api.request(originalReq)
        }
      } catch (err) {
        console.error(err)
        return Promise.reject(error)
      }
    } else if (error.response && error.response.status === 401
      && error.response.data.message === '만료된 리프레시 토큰입니다.') {
      deleteCookie('akiveUser')
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default api