import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import api from '../../services/api'
import { CompletionRateListContext } from '../../contexts'
import theme from '../../styles/theme'
import useModal from '../../hooks/useModal'

import * as S from './Main.styles'
import BasicSelect from '../SelectBoxes'
import Tooltip from '@mui/material/Tooltip'

import { ReactComponent as CalendarIcon } from '../../assets/images/calendar_icon.svg'

export default function YearlyStudy() {
  const [yearList, setYearList] = useState([])
  const { completionRateList,setCompleteRateList } = useContext(CompletionRateListContext)
  const { openModal } = useModal()

  const date = moment().format('YYYY-MM-DD')
  const dayOfWeek = [0, 1, 2, 3, 4, 5, 6] // 일 ~ 토
  const week = new Array(completionRateList.length + 1)
  const MONTH_ENG = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  }

  const DAY_OF_WEEK_ENG = {
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
  }

  const COMPLETION_RATE_COLORS = {
    ZERO: ['0%', '#FFFFFF'],
    ONE_TO_TWENTY: ['1% ~ 20%', '#FFEFF5'],
    TWENTY_ONE_TO_FORTY: ['21% ~ 40%', '#FFC8E2'],
    FORTY_ONE_TO_SIXTY: ['41% ~ 60%', '#FC7CAB'],
    SIXTY_ONE_TO_EIGHTY: ['61% ~ 80%', '#EB4893'],
    EIGHTY_ONE_TO_HUNDRED: ['81% ~ 100%', theme.colors.secondary],
  }

  let completeRateRangeList = []
  _.forIn(COMPLETION_RATE_COLORS, value => {
    completeRateRangeList.push(
      <>
        <div style={{ marginRight: '8px' }}>{value[0]}</div>
        <div style={{
          backgroundColor: value[1],
          borderColor: theme.colors.gray,
          borderRadius: '3px',
          borderStyle: 'solid',
          borderWidth: '1px',
          height: '16px',
          width: '16px',
          marginRight: '16px',
        }}/>
      </>
    )
  })

  useEffect(() => {
    api.get(`/reports/infos/performances/completion-rates/${moment(date).format('YYYY')}`)
      .then(result => {
        setCompleteRateList(result.data.completion_rate_list)
        setYearList(result.data.year_list)
      })
  }, [])

  async function handleYearChange(e) {
    try {
      const result = await api.get(`/reports/infos/performances/completion-rates/${e.target.value}`)
      setCompleteRateList(result.data.completion_rate_list)
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  function getCompletionRateColor(completionRate) {
    let backgroundColor = COMPLETION_RATE_COLORS.ZERO[1]
    let borderColor = theme.colors.gray

    if (_.isNull(completionRate)) {
      borderColor = COMPLETION_RATE_COLORS.ZERO[1]
    } else if (completionRate > 0 && completionRate <= 20) {
      backgroundColor =  COMPLETION_RATE_COLORS.ONE_TO_TWENTY[1]
    } else if (completionRate > 20 && completionRate <= 40) {
      backgroundColor = COMPLETION_RATE_COLORS.TWENTY_ONE_TO_FORTY[1]
    } else if (completionRate > 40 && completionRate <= 60) {
      backgroundColor = COMPLETION_RATE_COLORS.FORTY_ONE_TO_SIXTY[1]
    } else if (completionRate > 60 && completionRate <= 80) {
      backgroundColor = COMPLETION_RATE_COLORS.SIXTY_ONE_TO_EIGHTY[1]
    } else if (completionRate > 80 && completionRate <= 100) {
      backgroundColor = COMPLETION_RATE_COLORS.EIGHTY_ONE_TO_HUNDRED[1]
    }

    return { backgroundColor, borderColor }
  }

  return (
    <>
      <S.ContentsBox margin='10px 0 0 0'>
        <S.FlexContainer>
          <CalendarIcon className='title-icon' width='30px'/>
          <BasicSelect
            defaultValue={moment().format('YYYY')}
            handleChange={handleYearChange}
            itemList={
              _.map(yearList, year => {
                return {
                  type: year,
                  nameKo: year,
                }
              })
            }
            name='year_list'
            style={{ width: '100px', height: '32px', textAlign: 'center', marginRight: '10px' }}
          />
          <S.Title fontSize='1.3em'>연간 스터디</S.Title>
        </S.FlexContainer>
        <S.YearlyStudyTable>
          {
            _.map(dayOfWeek, dw => {
              return (
                dw === 0
                  ?
                  <tr>
                    {
                      _.map(week, (w, idx) => {
                        const month = completionRateList[idx-1] ? moment(completionRateList[idx-1][0].date).month() + 1 : null
                        const preMonth = completionRateList[idx-2] ? moment(completionRateList[idx-2][0].date).month() + 1 : null
                        return (
                          idx === 0
                            ? <td className='day-of-week-eng-td'>{DAY_OF_WEEK_ENG[dw]}</td>
                            : month === preMonth
                              ? <td/>
                              : <td className='month-eng-td'>{MONTH_ENG[month]}</td>
                        )
                      })
                    }
                  </tr>
                  : <tr>
                    {
                      _.map(week, (w, idx) => {
                        const { completion_rate, date } = _.find(completionRateList[idx-1], ['day_of_week', dw]) || { completion_rate: null }
                        return (
                          idx === 0
                            ? <td className='day-of-week-eng-td'>{DAY_OF_WEEK_ENG[dw]}</td>
                            : completion_rate !== null
                              ? <Tooltip title={`${date} 달성률 ${completion_rate}%`} arrow slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -10],
                                      },
                                    },
                                  ],
                                },
                              }}>
                                <td className='completion-rate-td' style={{ ...getCompletionRateColor(completion_rate) }}/>
                              </Tooltip>
                              : <td className='completion-rate-td' style={{ ...getCompletionRateColor(completion_rate) }}/>
                        )
                      })
                    }
                  </tr>
              )
            })
          }
        </S.YearlyStudyTable>
        <div style={{ display: 'flex', fontSize: '12px' }}>
          <div style={{ marginRight: '8px' }}>달성률</div>
          {completeRateRangeList}
        </div>
      </S.ContentsBox>
    </>
  )
}