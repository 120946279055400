import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CompletionRateListContext,
  StaticsContext,
  ProfileContext,
} from '../../contexts'
import { deleteCookie } from '../../utils/manageCookie'

import * as S from '../../components/main/Main.styles'
import { BasicBtn } from '../../components/Buttons'
import TodayStudy from '../../components/main/TodayStudy'
import TodayDailyLog from '../../components/main/TodayDailyLog'
import TodayTodo from '../../components/main/TodayTodo'
import YearlyStudy from '../../components/main/YearlyStudy'

import { ReactComponent as ColoredSmileIcon } from '../../assets/images/smile/colored_smile_icon.svg'

function MainContents() {
  const [completionRateList,setCompleteRateList] = useState([])
  const [statics, setStatics] = useState({})
  const { docs } = useContext(ProfileContext)
  const navigate = useNavigate()

  return (
    <S.ContentsLayout>
      {/* TODO Provider가 여러 개 중첩 되는 것에 대한 리팩토링 */}
      <CompletionRateListContext.Provider value={{ completionRateList, setCompleteRateList }}>
        <StaticsContext.Provider value={{ statics, setStatics }}>
          <div style={{ marginBottom: '10px', textAlign: 'right', }}>
            <strong>{docs.nickname}</strong>님 안녕하세요
            <ColoredSmileIcon width='30px' style={{ verticalAlign: 'middle' }}/>
            <BasicBtn
              onClick={() => {
                deleteCookie('akiveUser')
                navigate('/')
              }}
              height='40px'
              width='100px'
              style={{ marginLeft: '10px' }}
            >로그아웃</BasicBtn>
          </div>
          <TodayStudy/>
          <S.FlexContainer>
            <TodayDailyLog/>
            <TodayTodo/>
          </S.FlexContainer>
          <YearlyStudy/>
        </StaticsContext.Provider>
      </ CompletionRateListContext.Provider>
    </S.ContentsLayout>
  )
}

export default MainContents
