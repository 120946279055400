import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'
import { BasicSelectForForm } from '../../SelectBoxes'
import { BasicTextFieldForForm } from '../../InputBoxes'
import { CompleteBtn } from '../../Buttons'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { TimeField } from '@mui/x-date-pickers/TimeField'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'

export default function UpdateDailyLogModal(props) {
  const { control, handleSubmit, watch } = useForm()
  const { closeModal } = useModal()
  const watchFields = watch()
  const _dailyLog = _.filter(props.dailyLog, { idx: props.modifyDailyLogIdx })[0]
  const watchLogType = watch('log_type', _dailyLog?.log_type || 'study')
  const date = props.date

  /**
   * 데일리 로그를 추가한다.
   */
  function addDailyLog(data) {
    try {
      const { start_time, end_time, log_type, intensive_time, contents } = data
      api.post(`/daily-logs/${date}`
        , {
          contents,
          log_type,
          start_time: moment(start_time).format('YYYY-MM-DD HH:mm'),
          end_time: moment(end_time).format('YYYY-MM-DD HH:mm'),
          intensive_time: intensive_time
            ? (intensive_time.hours() * 3600) + (intensive_time.minutes() * 60) + intensive_time.seconds()
            : null,
        }).then(result => {
        props.setDailyLog(result.data.daily_log)

        if (props.setStatics) updateTodayStudy()
      })

      closeModal()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * 데일리 로그를 수정한다.
   */
  function modifyDailyLog(data) {
    try {
      const { start_time, end_time, log_type, intensive_time, contents } = data
      api.put(`daily-logs/${date}/${props.modifyDailyLogIdx}`
        , {
          contents,
          log_type,
          start_time: moment(start_time).format('YYYY-MM-DD HH:mm'),
          end_time: moment(end_time).format('YYYY-MM-DD HH:mm'),
          intensive_time: intensive_time ? (intensive_time.hours() * 3600) + (intensive_time.minutes() * 60) + intensive_time.seconds() : null,
        }).then(result => {
        props.setDailyLog(result.data.daily_log)

        if (props.setStatics) updateTodayStudy()
      })

      closeModal()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * 메인 페이지의 '오늘의 스터디' 데이터를 업데이트한다.
   */
  function updateTodayStudy() {
    try {
      api.get(`/reports/infos/performances/${date}`, {
        params: {
          type: 'daily',
        }
      }).then(result => { props.setStatics(result.data.statics) })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <S.Background />
      <S.ModalBox height='380px' width='350px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>{ props.isModify ? '한 일 수정' : '한 일 추가'}</S.Title>
          <S.FlexContainer>
            <BasicSelectForForm
              control={control}
              defaultValue={_dailyLog?.log_type || 'study'}
              label='유형'
              name='log_type'
              itemList={
                [{
                  type: 'study',
                  nameKo: '스터디',
                }
                  ,{
                  type: 'rest',
                  nameKo: '휴식',
                }
                  ,{
                  type: 'meal',
                  nameKo: '식사',
                }
                  ,{
                  type: 'etc',
                  nameKo: '기타',
                }]
              }
              style={{ flex: 1, marginRight: '10px'}}
            />
            <div style={{ flex: 2 }}>
              <BasicTextFieldForForm
                control={control}
                defaultValue={_dailyLog?.contents}
                isDisabled={watchLogType === 'rest'}
                label='한 일'
                name='contents'
                minWidth='100%'
                rules={{ required: '한 일을 입력해 주세요' }}
              />
            </div>
          </S.FlexContainer>
          <S.FlexContainer>
            <Controller
              control={control}
              defaultValue={_dailyLog?.start_time ? moment(_dailyLog?.start_time) : moment().add(-1, 'hour')}
              name='start_time'
              render={({ field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    defaultValue={_dailyLog?.start_time ? moment(_dailyLog?.start_time) : moment().add(-1, 'hour')}
                    disableFuture
                    format='HH:mm'
                    inputRef={field.ref}
                    label='시작 시간'
                    maxDateTime={watchFields.endTime}
                    minDateTime={moment().add(-1, 'days')}
                    onChange={field.onChange}
                    slotProps={{ textField: { size: 'small' }}}
                    sx={{ width: '50%' }}
                    views={['day', 'hours', 'minutes']}
                  />
                </LocalizationProvider>
              )}
            />
            <S.Sign>~</S.Sign>
            <Controller
              control={control}
              defaultValue={_dailyLog?.end_time ? moment(_dailyLog?.end_time) : moment()}
              name='end_time'
              render={({ field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment} >
                  <DateTimePicker
                    defaultValue={_dailyLog?.end_time ? moment(_dailyLog?.end_time) : moment()}
                    disableFuture
                    format='HH:mm'
                    inputRef={field.ref}
                    label='종료 시간'
                    maxDateTime={moment()}
                    minDateTime={moment(watchFields.start_time)}
                    onChange={field.onChange}
                    slotProps={{ textField: { size: 'small' }}}
                    sx={{ width: '50%' }}
                    views={['day', 'hours', 'minutes']}
                  />
                </LocalizationProvider>
              )}
            />
          </S.FlexContainer>
          {
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                control={control}
                defaultValue={moment(`${date} ${String(parseInt((_dailyLog?.intensive_time || 0) / 3600)).padStart(2, '0')}:${String(parseInt((_dailyLog?.intensive_time || 0) % 3600 / 60)).padStart(2, '0')}:${String(parseInt((_dailyLog?.intensive_time || 0) % 3600 % 60)).padStart(2, '0')}`)}
                name='intensive_time'
                render={({ field }) => (
                  <TimeField
                    defaultValue={moment()}
                    value={field.value}
                    disabled={watchLogType !== 'study'}
                    format='HH:mm:ss'
                    inputRef={field.ref}
                    label='집중시간(hh:mm:ss)'
                    onChange={field.onChange}
                    slotProps={{
                      textField: {
                        size: 'small',
                      }
                    }}
                    style={{ marginTop: '35px' }}
                  />
                )}
              />
            </LocalizationProvider>
          }
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(props.isModify ? modifyDailyLog : addDailyLog)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}