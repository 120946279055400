import _ from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'

import * as S from '../Modal.styles'
import { BasicTextFieldForForm } from '../../InputBoxes'
import { CompleteBtn } from '../../Buttons'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'

export default function UpdateSessionalPlanModal(props) {
  const { control, handleSubmit } = useForm()
  const { closeModal, openModal } = useModal()

  async function addSessionalPlan({ sessional_plan_name, session_start_date, session_end_date }) {
    try {
      api.post('/sessional-plans', {
        sessional_plan_name,
        session_end_date: moment(session_end_date).format('YYYY-MM-DD'),
        session_start_date: moment(session_start_date).format('YYYY-MM-DD'),
      }).then(result => {
        const { sessional_plan_list } = result.data
        props.setSessionalPlanList(sessional_plan_list)
        props.setCurrentSessinalPlanIdx(_.head(sessional_plan_list).idx)
        closeModal()
      })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  async function modifySessionalPlan({ sessional_plan_name, session_start_date, session_end_date }) {
    try {
      api.put(`/sessional-plans/${props.sessionalPlanIdx}`, {
        sessional_plan_name,
        session_end_date: moment(session_end_date).format('YYYY-MM-DD'),
        session_start_date: moment(session_start_date).format('YYYY-MM-DD'),
      }).then(result => {
        const { sessional_plan_list } = result.data
        props.setSessionalPlanList(sessional_plan_list)
        props.setCurrentSessinalPlanIdx(_.head(sessional_plan_list).idx)
        closeModal()
      })
    } catch (err) {
      console.error(err)
      openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message }})
    }
  }

  return (
    <>
      <S.Background />
      <S.ModalBox height='300px' width='350px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>{props.sessionalPlanIdx ? '세셔널 플랜 수정' : '세셔널 플랜 추가'}</S.Title>
          <BasicTextFieldForForm
            control={control}
            defaultValue={props?.sessionalPlan?.sessional_plan_info.session_name || null}
            label='세셔널 플랜 명'
            name='sessional_plan_name'
            minWidth='100%'
            rules={{ required: '세셔널 플랜 이름을 입력해 주세요' }}
          />
          <S.FlexContainer>
            <Controller
              control={control}
              defaultValue={
                props?.sessionalPlan?.sessional_plan_info.session_start_date
                  ? moment(props?.sessionalPlan?.sessional_plan_info.session_start_date)
                  : moment()}
              name='session_start_date'
              render={({ field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    defaultValue={
                      props?.sessionalPlan?.sessional_plan_info.session_start_date
                      ? moment(props?.sessionalPlan?.sessional_plan_info.session_start_date)
                      : moment()}
                    format='YY-MM-DD'
                    inputRef={field.ref}
                    label='세션 시작 날짜'
                    onChange={field.onChange}
                    slotProps={{ textField: { size: 'small' }}}
                    sx={{ width: '50%' }}
                  />
                </LocalizationProvider>
              )}
            />
            <S.Sign>~</S.Sign>
            <Controller
              control={control}
              defaultValue={
                props?.sessionalPlan?.sessional_plan_info.session_end_date
                  ? moment(props?.sessionalPlan?.sessional_plan_info.session_end_date)
                  : moment().add(1, 'month')}
              name='session_end_date'
              render={({ field}) => (
                <LocalizationProvider dateAdapter={AdapterMoment} >
                  <DatePicker
                    defaultValue={
                      props?.sessionalPlan?.sessional_plan_info.session_end_date
                        ? moment(props?.sessionalPlan?.sessional_plan_info.session_end_date)
                        : moment().add(1, 'month')}
                    format='YY-MM-DD'
                    inputRef={field.ref}
                    label='세션 종료 날짜'
                    onChange={field.onChange}
                    slotProps={{ textField: { size: 'small' }}}
                    sx={{ width: '50%' }}
                  />
                </LocalizationProvider>
              )}
            />
          </S.FlexContainer>
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(props.sessionalPlanIdx ? modifySessionalPlan : addSessionalPlan)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}