import useModal from '../../hooks/useModal'

import * as S from './Modal.styles'
import { CancelBtn, CompleteBtn } from '../Buttons'

export default function ConfirmModal(props) {
  const { closeModal } = useModal()
  return (
    <>
      <S.Background/>
      <S.ModalBox width='350px' height='150px'>
        <S.ModalContents width='75%'>
          <p>{props.contents}</p>
          <div style={{ display: 'flex' }}>
            <CancelBtn
              width='100%'
              height='40px'
              margin='20px 0 0 0'
              fontSize='1em'
              onClick={closeModal}
            >취소
            </CancelBtn>
            <CompleteBtn
              width='100%'
              height='40px'
              margin='20px 0 0 10px'
              fontSize='1em'
              onClick={() => {
                props.callbackFun(props.itemIdx)
                closeModal()
              }}
            >확인
            </CompleteBtn>
          </div>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}