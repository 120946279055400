import useModal from '../../hooks/useModal'

import * as S from './Modal.styles'
import { CompleteBtn } from '../Buttons'
import { ReactComponent as SadIcon } from '../../assets/images/alert/sad_icon.svg'
import { ReactComponent as SmileIcon } from '../../assets/images/smile/smile_icon.svg'

export default function AlertModal(props) {
  const { closeModal } = useModal()
  return (
    <>
      <S.Background/>
      <S.ModalBox width='350px' height='250px'>
        <S.ModalContents width='75%'>
          {
            props.isError ? <SadIcon width='30%' height='30%'/> : <SmileIcon width='30%' height='30%'/>
          }
          <p>{props.contents}</p>
          <CompleteBtn
            width='100%'
            height='40px'
            margin='20px 0 0 0'
            fontSize='1em'
            onClick={closeModal}
          >확인
          </CompleteBtn>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}
