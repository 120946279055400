import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import api from '../../services/api'
import useModal from '../../hooks/useModal'

import { AuthBox, AuthContainer, Title } from '../../components/Auth'
import { BasicPwInputForForm } from '../../components/InputBoxes'
import { CompleteBtn } from '../../components/Buttons'
import { setCookie } from '../../utils/manageCookie'

function SignUp() {
  const { control, handleSubmit, getValues } = useForm()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const email = query.get('email')

  async function join({ pw }) {
    api.post(`/users`, { email, pw, user_type: 'user' })
      .then(result => {
        openModal({ type: 'alert', props: { isError: false, contents: '회원 가입이 완료되었습니다. 환영합니다!' } })
        setCookie('akiveUser', result.data, 30, 'd')
        navigate('/main')
      })
      .catch((err) => openModal({ type: 'alert', props: { isError: true, contents: err.response?.data.message || err.message } }))
  }

  return (
    <AuthContainer>
      <AuthBox width='400px' height='300px'>
        <Title>비밀번호 설정</Title>
        <BasicPwInputForForm
          control={control}
          label='비밀번호'
          name='pw'
          style={{ marginBottom: '30px' }}
          minWidth='100%'
          rules={{
            required: '비밀번호를 입력해 주세요',
            pattern: {
              value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=!]).{8,}$/,
              message: `숫자와 영문, 특수문자 조합 \n 8자 이상이어야 합니다`,
            }
          }}
        />
        <BasicPwInputForForm
          control={control}
          label='비밀번호 확인'
          name='check__pw'
          minWidth='100%'
          rules={{
            required: '비밀번호 확인을 입력해 주세요',
            validate: v => v === getValues('pw') || '사용할 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
          }}
          style={{ marginBottom: '30px' }}
        />
        <CompleteBtn
          fontSize='1em'
          height='40px'
          width='100%'
          onClick={handleSubmit(join)}
        >확인
        </CompleteBtn>
      </AuthBox>
    </AuthContainer>
  )
}

export default SignUp
