import _ from 'lodash'

import theme from '../styles/theme'
import Tooltip from '@mui/material/Tooltip'

export default function ReportingStatus({ name, reportStatusList }) {
  reportStatusList = _.filter(reportStatusList, reportStatus => ! _.isEmpty(reportStatus))
  return (
    <>
      {
        _.compact(reportStatusList)?.length
          ?
          <div>
            <span style={{ marginRight: '10px', color: theme.colors.gray }}>{name}</span>
            {
              _.map(reportStatusList, reportStatus => {
                return (
                  <>
                    <Tooltip title={reportStatus?.date} arrow slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}>
                      <div
                        style={{
                          width: '1em',
                          height: '1em',
                          backgroundColor: reportStatus?.is_write ? '#EB4893' :  '',
                          border: `1px solid ${theme.colors.lightGray}`,
                          borderRadius: '5px',
                          display: 'inline-block',
                          marginRight: '10px',
                        }}
                      />
                    </Tooltip>
                  </>
                )
              })
            }
          </div>
          : <></>
      }
    </>
  )
}