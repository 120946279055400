import { Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { store } from './store'
import GlobalStyle from './styles/GlobalStyle'
import theme from './styles/theme'

import ModalContainer from './components/modals/ModalContainer'

import {
  SignIn,
  SignUp,
  FindPassword,
  EmailAuth,
  Home,
  Main,
  ProfileHome,
  ModifyProfile,
  SessionalPlan,
  WeeeklyPlan,
  ReportHome,
  ReportList,
  ReportDetail,
  WriteReport,
  ModifyReport,
  DailyLog,
  StudySetting,
  MainContents, PlannerHome
} from './pages'

function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyle/>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/main' element={<Main />}>
              <Route index element={<MainContents/>}/>
              <Route path='modify-profile' element={<ProfileHome/>}>
                <Route index element={<ModifyProfile/>}/>
              </Route>
              <Route path='planner' element={<PlannerHome/>}>
                <Route path='sessional-plan' element={<SessionalPlan/>}/>
                <Route path='sessional-plan/:spi_idx/weekly-plan' element={<WeeeklyPlan/>}/>
              </Route>
              <Route path='report' element={<ReportHome/>}>
                <Route path='list' element={<ReportList/>}/>
                <Route path=':report_idx' element={<ReportDetail/>}/>
                <Route path='write' element={<WriteReport/>}/>
                <Route path=':report_idx/modify' element={<ModifyReport/>}/>
              </Route>
              <Route path='daily-log/list' element={<DailyLog/>}/>
              <Route path='study-setting' element={<StudySetting/>}/>
            </Route>
            <Route path='/sign-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/email-auth' element={<EmailAuth/>}/>
            <Route path='/find-pw' element={<FindPassword/>}/>
          </Routes>
          <ModalContainer />
        </ThemeProvider>
      </Provider>
    </div>
  )
}

export default App
