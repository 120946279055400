import styled from 'styled-components'

export const ProgressBar = styled.progress`
  appearance: none;
  border-radius: 10px;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  
  &::-webkit-progress-bar {
    background: ${props => props.theme.colors.lightGray};
    border-radius:10px;
  }
  
  &::-webkit-progress-value {
    border-radius: 10px;
    background: ${props => props.theme.colors.secondary};
  }
`